import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorHandlers} from '@application/helper/error-handlers';
import {NavigationHelperService} from '@application/helper/navigation-helper/navigation-helper.service';
import {RouteUtils} from '@application/helper/routing/route-utils';
import {NavigationHistoryService} from '@application/navigation-history/navigation-history.service';
import {Editable} from '@domain/editable';
import {ProductConfiguration} from '@domain/product-configuration/product-configuration';
import {Drawing} from '@domain/production-schedule/drawing';
import {FinishingForProductionSchedule} from '@domain/production-schedule/finishing-for-production-schedule';
import {ProductionSchedule} from '@domain/production-schedule/production-schedule';
import {ProductionScheduleItemInPathGroup} from '@domain/production-schedule/production-schedule-item-in-path-group';
import {ProductionScheduleItemInPathGroupStatus} from '@domain/production-schedule/production-schedule-item-in-path-group-status';
import {ProductionScheduleOrderLine} from '@domain/production-schedule/production-schedule-order-line';
import {ProductionSchedulePath} from '@domain/production-schedule/production-schedule-path';
import {ProductionSchedulePathsOfColoredYarnSet} from '@domain/production-schedule/production-schedule-paths-of-colored-yarn-set';
import {ProductionSchedulePhase} from '@domain/production-schedule/production-schedule-phase.enum';
import {ProductionScheduleStatus} from '@domain/production-schedule/production-schedule-status.enum';
import {Permission} from '@domain/profile/permission.enum';
import {Subscription} from '@domain/profile/subscription';
import {PropertyValue} from '@domain/property-value';
import {TargetForListOfDrawingsEnum} from '@domain/target-for-list-of-drawings.enum';
import {PathWidth} from '@domain/textile-data/machine-quality/path-width';
import {Authentication, AUTHENTICATION} from '@infrastructure/http/authentication/authentication';
import {DRAWINGS, Drawings} from '@infrastructure/http/drawing/drawings';
import {FINISHINGS, Finishings} from '@infrastructure/http/finishing/finishings';
import {MACHINE_QUALITIES, MachineQualities} from '@infrastructure/http/machine-quality/machine-qualities';
import {PRODUCT_CONFIGURATIONS, ProductConfigurations} from '@infrastructure/http/product-configuration/product-configuration.interface';
import {PRODUCTION_ORDERS, ProductionOrders} from '@infrastructure/http/production-order/production-orders';
import {PRODUCTION_SCHEDULES, ProductionSchedules} from '@infrastructure/http/production-schedule/production-schedules';
import {ListDrawing} from '@presentation/components/drawing-list/list-drawing';
import {ListDrawingConfiguration} from '@presentation/components/drawing-list/list-drawing-configuration';
import {DrawingLibraryHelper} from '@presentation/pages/texedit/drawing-library/drawing-library-new-listview/drawing-library-helper';
import {AddProductionSchedule} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule';
import {ADD_PRODUCTION_SCHEDULE} from '@presentation/pages/texfab/production-schedule/add/add-production-schedule.service';
import {PRODUCTION_SCHEDULE_PLAN, ProductionSchedulePlan} from '@presentation/pages/texfab/production-schedule/add/plan/production-schedule-plan';
import {ConfirmProductionScheduleOnDesktopCommand} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-command';
import {ConfirmProductionScheduleOnDesktopResult} from '@presentation/pages/texfab/production-schedule/confirm/confirm-production-schedule-on-desktop-result';
import {OverviewListFinishing} from '@presentation/pages/textile-data/finishing/overview/overview-list-finishing';
import {
  AssertionUtils,
  BidirectionalMap,
  convertUnit,
  GridModel,
  LocalStorageService,
  ObjectUtils,
  SaveTypeForProductionSchedule,
  skeletonViewAnimation,
  TemporaryStateKey,
  TemporaryStateService,
  ToastService,
  TranslateService,
  UnhandledBackendError,
  Unit
} from '@vdw/angular-component-library';
import {cloneDeep, min, noop} from 'lodash-es';
import {forkJoin, Observable, Subject} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {
  AddProductionScheduleNavigationData,
  ProductionScheduleData,
  ProductionScheduleNameData,
  ProductionScheduleNavigationData,
  ProductionSchedulePhaseData
} from './add-production-schedule-navigation-data.type';
import {AddProductionScheduleNavigationState} from './add-production-schedule-navigation-state.interface';
import {BaseAddProductionScheduleNewPageComponent} from './base-add-production-schedule-new-page.component';
import {OrderLinesHelper} from './drawing-library/views/order-lines-helper';
import {ProductionScheduleInitialPreparationsNewComponent} from './initial-preparations-new/production-schedule-initial-preparations-new.component';
import {MenuItemsCanShowProperties} from './menu-items-new/menu-items-can-show-properties.interface';
import {MenuItemsLoadingProperties} from './menu-items-new/menu-items-loading-properties.interface';
import {PathContentSettingsDialogData} from './menu-items-new/path-content-settings-dialog-data.interface';
import {ProductionScheduleClickEvent} from './menu-items-new/production-schedule-click-event.enum';
import {ParametersForPreselection} from './plan/parameters-for-preselection';
import {ProductionSchedulePlanNewComponent} from './plan/production-schedule-plan-new.component';

@Component({
  templateUrl: './add-production-schedule-new-page.component.html',
  styleUrls: ['./add-production-schedule-new-page.component.scss'],
  animations: [skeletonViewAnimation('.form-field-skeleton-wrapper, .button-skeleton-wrapper, app-production-schedule-progress-new')]
})
export class AddProductionScheduleNewPageComponent extends BaseAddProductionScheduleNewPageComponent implements OnInit, Editable {
  private static readonly PRODUCTION_SCHEDULE_OVERVIEW_URL = RouteUtils.paths.texFab.productionOrder.absolutePath;
  private static readonly EDIT_PRODUCTION_SCHEDULE_URL: string = RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView.path;
  private static readonly DUPLICATE_PRODUCTION_SCHEDULE_URL: string = RouteUtils.paths.texFab.productionOrder.duplicateProductionOrderNewView.path;
  @ViewChild('initialPreparations') public initialPreparationsComponent: ProductionScheduleInitialPreparationsNewComponent;
  @ViewChild('productionSchedulePlan') public productionSchedulePlanComponent: ProductionSchedulePlanNewComponent;
  public listOfParametersForDefaultParameters: PropertyValue[] = [];
  public productionScheduleToAdd: ProductionSchedule;
  public productionScheduleWithPathLabels: ProductionSchedule;
  public pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks: number;
  public pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit: number;
  public lastProductionSchedulePhase: ProductionSchedulePhase = ProductionSchedulePhase.PRESELECTION;
  public currentProductionSchedulePhase: ProductionSchedulePhase;
  public isVerifyingFromConfigurePhase = false;
  public pathWidths: PathWidth[];
  public isRunningInitialPreparations = false;
  public isRunningInitialPreparationsToDesigns = false;
  public verifyingProductionSchedule = false;
  public isLoadingProductionSchedule = true;
  public completingProductionSchedule = false;
  public decreatingProductionOrder = false;
  public pathContentSettingsDialogData: PathContentSettingsDialogData = {pathContentPanelEnabled: true};
  public preselectedOrderlineIds: number[] = [];
  public preselectedDrawings: ListDrawingConfiguration[] = [];
  public preselectedConfigurationIds: number[] = [];
  public preselectedDesignIds: number[] = [];
  public isPreselectionMade = false;
  public preselectionListId: number;
  public justInitialized = false;
  public saveButtonClicked = false;
  public addProductionScheduleNavigationState: AddProductionScheduleNavigationState | null = null;
  public headerVisible = true;

  private alreadySetUpDefaultParameters: boolean;
  private showSkeletonViewForToolbar = false;
  private initialRestZoneLoaded = false;
  private initialFreeZoneLoaded = false;
  private initialPathZoneLoaded = false;
  private initialJuteZoneLoaded = false;
  private nextButtonTouched = false;
  private currentSubscription: Subscription;
  private confirmingProductionSchedule = false;
  private navigationHelperState: any;
  private readonly PRODUCTION_ORDER_TRANSLATION_KEY = 'PRODUCTION_ORDER.PRODUCTION_ORDER';
  private readonly localstoragePathContentPanelEnabledKey = 'pathContentPanelEnabled';
  private canLeavePageAfterCheckingGuard = new Subject<boolean>();

  private readonly ignoredStatuses: ProductionScheduleStatus[] = [
    ProductionScheduleStatus.CANCELLED,
    ProductionScheduleStatus.EXECUTED,
    ProductionScheduleStatus.EXECUTED_COMPLETE,
    ProductionScheduleStatus.EXECUTED_PARTIAL
  ];

  private readonly redirectToBuildStatuses: ProductionScheduleStatus[] = [
    ProductionScheduleStatus.CREATED,
    ProductionScheduleStatus.ARRIVED,
    ProductionScheduleStatus.FAILURE,
    ProductionScheduleStatus.PROCESSING,
    ProductionScheduleStatus.QUEUED,
    ProductionScheduleStatus.SENDING,
    ProductionScheduleStatus.TO_RECONFIRM,
    ProductionScheduleStatus.TRANSLATED
  ];

  public constructor(
    @Inject(PRODUCTION_SCHEDULES) private readonly productionSchedules: ProductionSchedules,
    @Inject(PRODUCTION_ORDERS) private readonly productionOrders: ProductionOrders,
    @Inject(MACHINE_QUALITIES) private readonly machineQualities: MachineQualities,
    @Inject(PRODUCTION_SCHEDULE_PLAN) private readonly productionSchedulePlan: ProductionSchedulePlan,
    @Inject(ADD_PRODUCTION_SCHEDULE) private readonly addProductionSchedule: AddProductionSchedule,
    @Inject(FINISHINGS) private readonly finishings: Finishings,
    @Inject(AUTHENTICATION) private readonly authentication: Authentication,
    @Inject(PRODUCT_CONFIGURATIONS) private readonly productConfigurations: ProductConfigurations,
    @Inject(DRAWINGS) private drawings: Drawings,
    private readonly navigationHistory: NavigationHistoryService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly confirmProductionScheduleOnDesktopCommand: ConfirmProductionScheduleOnDesktopCommand,
    private readonly navigationHelperService: NavigationHelperService<ProductionScheduleNavigationData>,
    private readonly formBuilder: FormBuilder,
    private readonly toastService: ToastService,
    private readonly localStorage: LocalStorageService,
    private readonly temporaryStateService: TemporaryStateService<ProductionSchedule>,
    private readonly drawingLibraryHelper: DrawingLibraryHelper,
    private readonly orderlinesHelper: OrderLinesHelper,
    activatedRoute: ActivatedRoute
  ) {
    super(activatedRoute);
  }

  public ngOnInit(): void {
    this.getRouteStateProperties();
    this.showSkeletonViewForToolbar = this.isCurrentRouteEditProductionScheduleRoute() || this.isCurrentRouteDuplicateProductionScheduleRoute();
    this.currentSubscription = this.authentication.getCurrentSubscription();

    this.setProductionScheduleInitialPreparationsFormFields();
    this.setPathContentSettingsDialogData();

    this.initialiseAddProductionSchedule();

    this.navigationHelperState = {productionSchedulePhase: this.currentProductionSchedulePhase};
  }

  public onNavigationHelperDestroy(): void {
    this.setFilterModelsToZero();

    const productionSchedule = this.initialPreparationsComponent?.getConfiguredProductionSchedule();
    if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      this.navigationHelperState['productionSchedule'] = productionSchedule;
    }

    if (!AssertionUtils.isNullOrUndefined(this.currentProductionSchedulePhase)) {
      this.navigationHelperState['productionSchedulePhase'] = this.currentProductionSchedulePhase;
    }

    const productionOrderName = this.productionScheduleInitialPreparationsForm?.value?.name;
    if (!AssertionUtils.isNullOrUndefined(productionOrderName) && productionOrderName !== '' && productionSchedule?.name !== productionOrderName) {
      this.navigationHelperState['name'] = productionOrderName;
    }

    this.navigationHelperService.savePartialState<Partial<AddProductionScheduleNavigationData>>(this.navigationHelperState);
  }

  public canShowInitialPreparationsForProductionSchedule(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE && this.listOfParametersForDefaultParameters.length > 0 && this.listOfMachineQualityCustomSettings.length > 0;
  }

  public canShowPreselectionForProductionSchedule(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.PRESELECTION;
  }

  public selectedDrawingsChanged(listDrawings: ListDrawing[]): void {
    this.productionScheduleToAdd.designs = [];

    listDrawings.forEach((listDrawing: ListDrawing) => {
      if (listDrawing instanceof ListDrawingConfiguration) {
        this.productionScheduleToAdd.designs.push(listDrawing.drawing);
      }
    });

    this.initialPreparationsChanged();
    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(true);
  }

  public restZoneLoaded(): void {
    this.initialRestZoneLoaded = true;
  }

  public freeZoneLoaded(): void {
    this.initialFreeZoneLoaded = true;
  }

  public pathZoneLoaded(): void {
    this.initialPathZoneLoaded = true;
  }

  public juteZoneLoaded(): void {
    this.initialJuteZoneLoaded = true;
  }

  public setHeaderVisibility(hideHeader: boolean): void {
    this.headerVisible = !hideHeader;
  }

  public productionScheduleInitialised(productionSchedule: ProductionSchedule, productionSchedulePhase?: ProductionSchedulePhase): void {
    const navigationState: {completingPartially?: boolean; phase?: ProductionSchedulePhase} = this.navigationHistory.getState();

    const completingPartially = !!navigationState?.completingPartially;
    if (!AssertionUtils.isNullOrUndefined(productionSchedule.finishing)) {
      this.finishings.getOverviewListFinishingById(productionSchedule.finishing.id).subscribe((overviewListFinishing: OverviewListFinishing) => {
        productionSchedule.updateFinishing(FinishingForProductionSchedule.fromOverviewListFinishing(overviewListFinishing));
      });
    }

    this.showSkeletonViewForToolbar = false;
    this.productionScheduleToAdd = productionSchedule;
    this.productionScheduleToAdd.completingPartially = completingPartially;

    this.isLoadingProductionSchedule = false;

    const emptyProductionSchedulePhaseState = {productionSchedulePhase: null} as ProductionSchedulePhaseData;
    const productionSchedulePhaseState = this.navigationHelperService.getPartialState<ProductionSchedulePhaseData>(Object.keys(emptyProductionSchedulePhaseState));
    if (
      !AssertionUtils.isNullOrUndefined(productionSchedulePhaseState) &&
      !ObjectUtils.isDeepEqual(emptyProductionSchedulePhaseState, productionSchedulePhaseState) &&
      !AssertionUtils.isNullOrUndefined(productionSchedulePhaseState.productionSchedulePhase)
    ) {
      if (productionSchedulePhaseState.productionSchedulePhase === ProductionSchedulePhase.BUILD) {
        this.removePathLabelsForBuilderStep();
      }

      this.currentProductionSchedulePhase = productionSchedulePhaseState.productionSchedulePhase;
    } else if (navigationState.phase) {
      if (navigationState.phase === ProductionSchedulePhase.BUILD) {
        this.removePathLabelsForBuilderStep();
      }
      this.currentProductionSchedulePhase = navigationState.phase;
    } else if (this.isCurrentRouteDuplicateProductionScheduleRoute()) {
      this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      this.productionScheduleToAdd.status = ProductionScheduleStatus.DRAFT;
    } else if (!AssertionUtils.isNullOrUndefined(productionSchedulePhase) && productionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      this.currentProductionSchedulePhase = productionSchedulePhase;
      this.removePathLabelsForBuilderStep();
    } else {
      this.setCorrectProductionSchedulePhaseAfterInitialization();
    }

    this.updateProducedAmount();
    this.updateProductionScheduleItemInPathGroupStatus();
    this.setProductionScheduleInitialPreparationsFormFieldsValues(this.productionScheduleToAdd);
  }

  public initialPreparationsChanged(): void {
    const productionSchedulePhaseOrderMap = new BidirectionalMap<ProductionSchedulePhase, number>([
      [ProductionSchedulePhase.PRESELECTION, 0],
      [ProductionSchedulePhase.CONFIGURE, 1],
      [ProductionSchedulePhase.BUILD, 2],
      [ProductionSchedulePhase.VERIFY, 3],
      [ProductionSchedulePhase.COMPLETE, 4]
    ]);

    this.lastProductionSchedulePhase = productionSchedulePhaseOrderMap.getKey(
      min([productionSchedulePhaseOrderMap.getValue(this.lastProductionSchedulePhase), productionSchedulePhaseOrderMap.getValue(ProductionSchedulePhase.BUILD)])
    );
  }

  public canShowProductionSchedulePlan(): boolean {
    return (
      !AssertionUtils.isNullOrUndefined(this.productionScheduleToAdd) &&
      !this.isLoadingProductionSchedule &&
      this.currentProductionSchedulePhase !== ProductionSchedulePhase.CONFIGURE &&
      this.currentProductionSchedulePhase !== ProductionSchedulePhase.PRESELECTION
    );
  }

  public setProductionSchedulePhase(phase: ProductionSchedulePhase): void {
    this.nextButtonTouched = false;
    this.currentProductionSchedulePhase = phase;
    if (this.lastProductionSchedulePhase < phase) {
      this.lastProductionSchedulePhase = phase;
    }
  }

  public productionSchedulePlanChanged(): void {
    this.lastProductionSchedulePhase = ProductionSchedulePhase.BUILD;
  }

  public productionScheduleVerified({confirmImmediately, error}: {confirmImmediately: boolean; error: boolean}): void {
    this.verifyingProductionSchedule = false;
    if (!error) {
      this.setProductionSchedulePhase(ProductionSchedulePhase.VERIFY);

      if (confirmImmediately) {
        this.nextButtonTouched = true;

        if (this.productionScheduleToAdd.isProductionScheduleVerifiedWithNoErrors() && !this.productionScheduleToAdd.hasProductionScheduleErrors()) {
          this.confirmProductionSchedule();
        }
      }
    }
  }

  public navigateToOverview(): void {
    this.router.navigateByUrl(AddProductionScheduleNewPageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
  }

  public navigateBack(): void {
    this.nextButtonTouched = true;

    if (this.productionScheduleToAdd?.canBeModified() && !this.isDuplicatingProductionOrder()) {
      switch (this.currentProductionSchedulePhase) {
        case ProductionSchedulePhase.PRESELECTION:
          this.navigateToPreviousRoute();
          break;
        case ProductionSchedulePhase.CONFIGURE:
          let formsAreValid = this.validateConfigurePhaseForms();
          if (formsAreValid) {
            this.setProductionSchedulePhase(ProductionSchedulePhase.PRESELECTION);
          }
          break;
        case ProductionSchedulePhase.BUILD:
        case ProductionSchedulePhase.VERIFY:
          this.navigateBackFromVerify();
          break;
      }
    } else {
      this.navigateToPreviousRoute();
    }
  }

  public getMenuItemsLoadingProperties(): MenuItemsLoadingProperties {
    return {
      isLoading: this.isLoadingProductionSchedule,
      preselectionLoading: this.isRunningInitialPreparationsToDesigns,
      nextPhaseLoading: this.isNextPhaseLoading(),
      completeLoading: this.completingProductionSchedule,
      decreateLoading: this.decreatingProductionOrder,
      initialRestZoneLoaded: this.initialRestZoneLoaded,
      initialFreeZoneLoaded: this.initialFreeZoneLoaded,
      initialPathZoneLoaded: this.initialPathZoneLoaded,
      initialJuteZoneLoaded: this.initialJuteZoneLoaded
    };
  }

  public getMenuItemsCanShowProperties(): MenuItemsCanShowProperties {
    return {
      canShowInvalidFormMessageError: this.canShowInvalidFormMessageError(),
      canShowNavigateToNextPhaseButton: this.canShowProductionScheduleNavigationForPhases(),
      canShowCompleteButton: this.productionScheduleToAdd?.completingPartially,
      canShowDecreatedButton: this.canBeDecreated(),
      canShowCancelButton: this.canShowCancelButton()
    };
  }

  public confirmProductionSchedule(): void {
    if (!this.confirmingProductionSchedule) {
      this.confirmingProductionSchedule = true;

      this.confirmProductionScheduleOnDesktopCommand
        .execute(this.productionScheduleToAdd, this.listOfParametersForDefaultParameters)
        .pipe(finalize(() => (this.confirmingProductionSchedule = false)))
        .subscribe((confirmProductionScheduleOnDesktopResult: ConfirmProductionScheduleOnDesktopResult) => {
          if (confirmProductionScheduleOnDesktopResult === ConfirmProductionScheduleOnDesktopResult.CONFIRMED) {
            this.productionSchedulePlan.disableUnsavedChanges();
            this.router.navigateByUrl(AddProductionScheduleNewPageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
          } else if (confirmProductionScheduleOnDesktopResult === ConfirmProductionScheduleOnDesktopResult.PLAN_ADJUSTMENT_NEEDED) {
            this.productionScheduleToAdd.status = ProductionScheduleStatus.DRAFT;
            this.productionSchedules.updateWithMachineQualityChanged(this.productionScheduleToAdd).subscribe(noop);
            if (this.currentProductionSchedulePhase === ProductionSchedulePhase.VERIFY) {
              this.navigateBack();
            }
          }
        });
    }
  }

  public canShowSkeletonViewForToolbar(): boolean {
    return this.showSkeletonViewForToolbar;
  }

  public canConfirmProductionSchedule(): boolean {
    return this.productionScheduleToAdd.isProductionScheduleVerifiedWithNoErrors() && !this.productionScheduleToAdd.hasProductionScheduleErrors();
  }

  public isDirty(): boolean {
    return this.addProductionSchedule.hasUnsavedFormChanges();
  }

  public hasUnsavedBuildingChanges(): boolean {
    return this.productionSchedulePlan.hasUnsavedChanges(true);
  }

  public notifyChangesToForm(isDirty: boolean): void {
    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(isDirty);
  }

  public canShowProductionScheduleNavigationForPhases(): boolean {
    return this.canShowSkeletonViewForToolbar() || this.productionScheduleToAdd?.canBeModified();
  }

  public canShowInvalidFormMessageError(): boolean {
    const canShowInvalidFormMessageError = this.nextButtonTouched && !this.canNavigateToNextPhase();

    if (this.nextButtonTouched && !canShowInvalidFormMessageError) {
      this.nextButtonTouched = false;
    }

    return canShowInvalidFormMessageError;
  }

  public getInvalidFormMessageError(): string {
    switch (this.currentProductionSchedulePhase) {
      case ProductionSchedulePhase.VERIFY:
      case ProductionSchedulePhase.CONFIGURE:
        return 'GENERAL.ERRORS.CHECK_INVALID_FIELDS';
      case ProductionSchedulePhase.PRESELECTION:
        return this.translate.instant('GENERAL.ERRORS.NO_OBJECT_CHOSEN', {object: this.translate.instant('DESIGN_LIBRARY.DESIGN', {count: 2}).toLowerCase()});
      case ProductionSchedulePhase.BUILD:
        return this.productionSchedulePlan.hasUnsavedChanges(true)
          ? 'PRODUCTION_ORDER.ERRORS.CHANGES_IN_PROGRESS'
          : this.translate.instant('GENERAL.ERRORS.NO_OBJECT_ADDED', {object: this.translate.instant('DESIGN_LIBRARY.DESIGN', {count: 2}).toLowerCase()});
    }
  }

  public saveConfigurationPhaseFromLeaveDialog(): Subject<boolean> {
    this.nextButtonTouched = true;
    const formsAreValid = this.validateConfigurePhaseForms();
    if (this.canNavigateToNextPhase() && formsAreValid) {
      this.productionScheduleToAdd = this.initialPreparationsComponent.getProductionSchedule();
      this.isRunningInitialPreparations = true;

      this.isCreatingNewProductionSchedule()
        ? this.createProductionSchedule(ProductionSchedulePhase.BUILD, this.qualityHasChanged(), false)
        : this.initialPreparationsCompleted(ProductionSchedulePhase.BUILD, this.qualityHasChanged(), false);
    } else {
      this.canLeavePageAfterCheckingGuard.next(false);
    }

    return this.canLeavePageAfterCheckingGuard;
  }

  public navigateToNextPhase(): void {
    this.nextButtonTouched = true;

    let formsAreValid = true;
    if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      formsAreValid = this.validateConfigurePhaseForms();
    }

    if (this.canNavigateToNextPhase() && formsAreValid) {
      switch (this.currentProductionSchedulePhase) {
        case ProductionSchedulePhase.CONFIGURE:
          this.productionScheduleToAdd = this.initialPreparationsComponent.getProductionSchedule();
          this.isRunningInitialPreparations = true;
          this.isCreatingNewProductionSchedule()
            ? this.createProductionSchedule(ProductionSchedulePhase.BUILD, this.qualityHasChanged())
            : this.initialPreparationsCompleted(ProductionSchedulePhase.BUILD, this.qualityHasChanged());
          break;
        case ProductionSchedulePhase.PRESELECTION:
          this.isPreselectionMade = true;
          if (AssertionUtils.isNullOrUndefined(this.preselectionListId)) {
            this.createNewPreselectedOrderlinesAndDrawingsList();
          } else {
            this.updatePreselectedOrderlinesAndDrawings();
          }
          this.setProductionSchedulePhase(ProductionSchedulePhase.CONFIGURE);
          break;
        case ProductionSchedulePhase.BUILD:
          this.productionSchedulePlanComponent.verifyProductionSchedule();
          break;
        case ProductionSchedulePhase.VERIFY: {
          this.confirmProductionSchedule();
        }
      }
    }

    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);
  }

  public isNextPhaseLoading(): boolean {
    return this.productionSchedulePlanComponent?.isVerifyingProductionSchedule || this.verifyingProductionSchedule || this.isRunningInitialPreparations || this.confirmingProductionSchedule;
  }

  public navigateToPreselection(): void {
    this.nextButtonTouched = true;

    this.setProductionSchedulePhase(ProductionSchedulePhase.PRESELECTION);

    this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);
  }

  public completeProductionSchedule(): void {
    this.completingProductionSchedule = true;
    this.productionSchedulePlan.disableUnsavedChanges();
    this.productionSchedulePlanComponent
      .completePartially()
      .pipe(
        finalize(() => (this.completingProductionSchedule = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.navigateToOverview();
      });
  }

  public decreateProductionOrder(): void {
    this.decreatingProductionOrder = true;

    const decreatedProductionOrder = cloneDeep(this.productionScheduleToAdd);
    decreatedProductionOrder.id = `${decreatedProductionOrder.id}`;
    decreatedProductionOrder.status = ProductionScheduleStatus.DRAFT;
    decreatedProductionOrder.removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets();

    this.productionSchedules
      .decreateProductionOrder(decreatedProductionOrder)
      .pipe(
        finalize(() => (this.decreatingProductionOrder = false)),
        takeUntil(this.unSubscribeOnViewDestroy)
      )
      .subscribe(() => {
        this.productionScheduleToAdd = decreatedProductionOrder;
        this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      });
  }

  public canBeDecreated(): boolean {
    return (
      this.productionScheduleToAdd?.canBeDecreated() ||
      [ProductionScheduleStatus.ARRIVED, ProductionScheduleStatus.SENDING, ProductionScheduleStatus.QUEUING, ProductionScheduleStatus.QUEUED].includes(this.productionScheduleToAdd?.status)
    );
  }

  public canShowCancelButton(): boolean {
    return this.currentProductionSchedulePhase === ProductionSchedulePhase.VERIFY && this.currentSubscription?.hasPermission(Permission.TEXFAB_VERIFY_NEW);
  }

  public onClickEvent(type: ProductionScheduleClickEvent): void {
    switch (type) {
      case ProductionScheduleClickEvent.PRESELECTION:
        if (this.validateConfigurePhaseForms()) {
          this.navigateToPreselection();
        }
        break;
      case ProductionScheduleClickEvent.NEXT_PHASE:
        this.navigateToNextPhase();
        break;
      case ProductionScheduleClickEvent.COMPLETE:
        this.completeProductionSchedule();
        break;
      case ProductionScheduleClickEvent.DECREATE:
        this.decreateProductionOrder();
        break;
      case ProductionScheduleClickEvent.SAVE:
        this.saveProductionSchedule(SaveTypeForProductionSchedule.SAVE);
        break;
      case ProductionScheduleClickEvent.SAVE_AND_CREATE_NEW:
        this.saveProductionSchedule(SaveTypeForProductionSchedule.SAVE_AND_CREATE_NEW);
        break;
      case ProductionScheduleClickEvent.SAVE_AND_GO_TO_PRESELECTION:
        this.saveProductionSchedule(SaveTypeForProductionSchedule.SAVE_AND_GO_TO_PRESELECTION);
        break;
      case ProductionScheduleClickEvent.SAVE_AND_QUIT:
        this.saveProductionSchedule(SaveTypeForProductionSchedule.SAVE_AND_QUIT);
        break;
      case ProductionScheduleClickEvent.CANCEL:
        this.navigateToPreviousRoute();
        break;
    }
  }

  public onProductionSchedulePhaseClicked(event: ProductionSchedulePhase): void {
    const {status} = this.productionScheduleToAdd;
    this.nextButtonTouched = true;

    let formsAreValid = true;
    if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE) {
      formsAreValid = this.validateConfigurePhaseForms();
    }

    if (this.ignoredStatuses.includes(status)) {
      return;
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.PRESELECTION && (event === ProductionSchedulePhase.BUILD || event === ProductionSchedulePhase.VERIFY)) {
      if (!AssertionUtils.isNullOrUndefined(this.productionScheduleToAdd.id)) {
        this.updatePreselectedOrderlinesAndDrawings();
        this.setProductionSchedulePhase(event);
      }
      return;
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.PRESELECTION && event === ProductionSchedulePhase.CONFIGURE) {
      if (AssertionUtils.isNullOrUndefined(this.preselectionListId)) {
        this.createNewPreselectedOrderlinesAndDrawingsList();
      } else {
        this.updatePreselectedOrderlinesAndDrawings();
      }

      const productionSchedule = this.temporaryStateService.getTemporaryState(TemporaryStateKey.PRODUCTION_SCHEDULE);
      if (!AssertionUtils.isNullOrUndefined(productionSchedule)) {
        this.productionScheduleToAdd = productionSchedule;
        this.setProductionScheduleInitialPreparationsFormFieldsValues(productionSchedule);
      }
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE && event !== ProductionSchedulePhase.PRESELECTION) {
      if (this.validateConfigurePhaseForms()) {
        this.handleConfigurePhase(event);
      }
      return;
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.CONFIGURE && event === ProductionSchedulePhase.PRESELECTION) {
      if (formsAreValid) {
        this.setProductionSchedulePhase(event);
      } else {
        return;
      }
    } else if (event === ProductionSchedulePhase.VERIFY) {
      if (this.redirectToBuildStatuses.includes(status)) {
        this.setProductionSchedulePhase(ProductionSchedulePhase.BUILD);
        return;
      }

      this.productionSchedulePlanComponent.verifyProductionSchedule();
      return;
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.VERIFY && event === ProductionSchedulePhase.BUILD) {
      this.navigateBackFromVerify();
    } else if (this.currentProductionSchedulePhase === ProductionSchedulePhase.VERIFY) {
      this.pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit =
        this.productionScheduleToAdd.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit;
      this.pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks =
        this.productionScheduleToAdd.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks;
    }

    this.setProductionSchedulePhase(event);
  }

  public isCreatingNewProductionSchedule(): boolean {
    return !this.isCurrentRouteEditProductionScheduleRoute() && !this.isCurrentRouteDuplicateProductionScheduleRoute();
  }

  public getHeaderIcon(): string {
    return this.isEditingProductionOrder() ? 'details-blue' : 'add-blue';
  }

  public getActionText(): string {
    const title = this.isCurrentRouteEditProductionScheduleRoute() ? 'GENERAL.ACTIONS.EDIT_OBJECT' : 'GENERAL.ACTIONS.CREATE_OBJECT';
    return this.translate.instant(title, {object: this.translate.instant(this.PRODUCTION_ORDER_TRANSLATION_KEY, {count: 1}).toLowerCase()});
  }

  public preselectedOrderlineIdsChanged(orderlineIds: number[]): void {
    this.preselectedOrderlineIds = orderlineIds;
  }

  public preselectedDrawingsChanged(drawings: ListDrawingConfiguration[]): void {
    this.preselectedDrawings = drawings;
  }

  public preselectedDrawingIdsChanged(drawingIds: number[]): void {
    this.preselectedDesignIds = drawingIds;
  }

  public preselectedConfigurationIdsChanged(configurationIds: number[]): void {
    this.preselectedConfigurationIds = configurationIds;
  }

  public onPathContentSettingsDialogDataChanged(event: PathContentSettingsDialogData): void {
    this.localStorage.set(this.localstoragePathContentPanelEnabledKey, event.pathContentPanelEnabled);
    this.pathContentSettingsDialogData.pathContentPanelEnabled = event.pathContentPanelEnabled;
    this.productionSchedulePlanComponent.onPathContentSettingsDialogDataChanged();
  }

  public validateConfigurePhaseForms(): boolean {
    const zonesLoaded = this.initialRestZoneLoaded && this.initialFreeZoneLoaded && this.initialJuteZoneLoaded && this.initialPathZoneLoaded;
    return this.initialPreparationsComponent?.validateForms() && zonesLoaded;
  }

  private setCorrectProductionSchedulePhaseAfterInitialization(): void {
    if (!this.productionScheduleToAdd.isDraft()) {
      if (this.hasStatusThatShouldBeSentToVerifyStep(this.productionScheduleToAdd.status) || this.productionScheduleToAdd.step === ProductionSchedulePhase.VERIFY) {
        this.currentProductionSchedulePhase = ProductionSchedulePhase.VERIFY;
      } else {
        this.removePathLabelsForBuilderStep();
        this.currentProductionSchedulePhase = ProductionSchedulePhase.BUILD;
      }
    } else if (!this.productionScheduleToAdd.hasBeenConfigured()) {
      this.currentProductionSchedulePhase = ProductionSchedulePhase.PRESELECTION;
    } else {
      this.removePathLabelsForBuilderStep();
      this.currentProductionSchedulePhase = ProductionSchedulePhase.BUILD;
    }
  }

  private removePathLabelsForBuilderStep(): void {
    this.productionScheduleToAdd.removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets();
    this.productionSchedulePlan.cancelOngoingRecoloring();
  }

  private saveProductionSchedule(saveType: SaveTypeForProductionSchedule): void {
    this.saveButtonClicked = true;
    this.productionSchedulePlan.manualSaveProductionSchedule(this.productionScheduleToAdd.id.toString(), this.currentProductionSchedulePhase);

    if (saveType === SaveTypeForProductionSchedule.SAVE_AND_CREATE_NEW) {
      this.setFilterModelsToZero();
      this.navigationHelperState['productionSchedule'] = this.getNewScheduleWithOldScheduleInformation();
      this.router.navigate([RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView.absolutePath], {
        state: {
          goToPreselection: false,
          preselectionId: this.preselectionListId
        }
      });
    } else if (saveType === SaveTypeForProductionSchedule.SAVE_AND_GO_TO_PRESELECTION) {
      this.setFilterModelsToZero();
      this.router.navigate([RouteUtils.paths.texFab.productionOrder.addProductionOrderNewView.absolutePath], {
        state: {
          goToPreselection: true,
          preselectionId: null
        }
      });
    } else if (saveType === SaveTypeForProductionSchedule.SAVE_AND_QUIT) {
      this.setFilterModelsToZero();
      this.router.navigate([RouteUtils.paths.texFab.productionOrder.absolutePath]);
    }

    setTimeout(() => {
      this.saveButtonClicked = false;
    }, 500);
  }

  private setFilterModelsToZero(): void {
    this.drawingLibraryHelper.savedFilterModel = null;
    this.orderlinesHelper.savedFilterModel = null;
  }

  private getNewScheduleWithOldScheduleInformation(): ProductionSchedule {
    const productionSchedule = this.productionScheduleToAdd;

    productionSchedule.name = null;
    productionSchedule.productionSchedulePathsOfColoredYarnSets = [];
    productionSchedule.completingPartially = false;
    productionSchedule.runId = undefined;
    productionSchedule.orderLines = [];
    productionSchedule.designs = [];

    return productionSchedule;
  }

  private handleConfigurePhase(nextPhase: ProductionSchedulePhase = ProductionSchedulePhase.BUILD): void {
    this.productionScheduleToAdd = this.initialPreparationsComponent.getProductionSchedule();
    this.isRunningInitialPreparations = true;
    this.isCreatingNewProductionSchedule() ? this.createProductionSchedule(nextPhase, this.qualityHasChanged()) : this.initialPreparationsCompleted(nextPhase, this.qualityHasChanged());
  }

  private updatePreselectedOrderlinesAndDrawings(): void {
    if (!AssertionUtils.isNullOrUndefined(this.preselectionListId)) {
      this.productionSchedules
        .updatePreselectionList(this.preselectionListId, this.preselectedDesignIds, this.preselectedOrderlineIds, null, this.preselectedConfigurationIds)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe();
    }
  }

  private createNewPreselectedOrderlinesAndDrawingsList(): void {
    if (!AssertionUtils.isEmpty(this.preselectedConfigurationIds) || !AssertionUtils.isEmpty(this.preselectedDesignIds) || !AssertionUtils.isEmpty(this.preselectedOrderlineIds)) {
      this.productionSchedules
        .savePreselectionList(0, this.preselectedDesignIds, this.preselectedOrderlineIds, null, this.preselectedConfigurationIds)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((preselectionListId: number) => {
          this.preselectionListId = preselectionListId;
          if (!AssertionUtils.isNullOrUndefined(this.productionScheduleToAdd)) {
            this.productionScheduleToAdd.preselectionId = preselectionListId;
          }
        });
    }
  }

  private qualityHasChanged(): boolean {
    const oldQualityId = this.initialPreparationsComponent.oldMachineQuality?.id;
    return !AssertionUtils.isNullOrUndefined(oldQualityId) && oldQualityId !== this.productionScheduleToAdd.machineQuality.id;
  }

  private hasStatusThatShouldBeSentToVerifyStep(status: ProductionScheduleStatus): boolean {
    return (
      status === ProductionScheduleStatus.EXECUTED ||
      status === ProductionScheduleStatus.EXECUTED_COMPLETE ||
      status === ProductionScheduleStatus.EXECUTED_PARTIAL ||
      status === ProductionScheduleStatus.EXECUTING ||
      status === ProductionScheduleStatus.FINALIZED ||
      status === ProductionScheduleStatus.FINALIZING ||
      status === ProductionScheduleStatus.TRANSLATING ||
      status === ProductionScheduleStatus.TRANSLATED ||
      status === ProductionScheduleStatus.SENDING ||
      status === ProductionScheduleStatus.ARRIVED ||
      status === ProductionScheduleStatus.QUEUED ||
      status === ProductionScheduleStatus.QUEUING ||
      status === ProductionScheduleStatus.FAILURE ||
      status === ProductionScheduleStatus.PROCESSED ||
      status === ProductionScheduleStatus.PROCESSING ||
      status === ProductionScheduleStatus.TO_RECONFIRM ||
      status === ProductionScheduleStatus.CANCELLED ||
      status === ProductionScheduleStatus.TO_PLAN ||
      status === ProductionScheduleStatus.NO_PRODUCTION_INFORMATION_RECEIVED ||
      status === ProductionScheduleStatus.PRODUCED_PARTIALLY ||
      status === ProductionScheduleStatus.PRODUCED_COMPLETELY ||
      status === ProductionScheduleStatus.PRODUCING ||
      status === ProductionScheduleStatus.REPLANNED_WITH_WARNINGS ||
      status === ProductionScheduleStatus.REPLANNED ||
      status === ProductionScheduleStatus.REPLANNING ||
      status === ProductionScheduleStatus.PLANNED ||
      status === ProductionScheduleStatus.PLANNING ||
      status === ProductionScheduleStatus.SENT ||
      status === ProductionScheduleStatus.CREATED
    );
  }

  private canNavigateToNextPhase(): boolean {
    switch (this.currentProductionSchedulePhase) {
      case ProductionSchedulePhase.CONFIGURE:
        return this.initialPreparationsComponent?.getConfiguredProductionSchedule()?.hasBeenConfigured();
      case ProductionSchedulePhase.PRESELECTION:
        return true;
      case ProductionSchedulePhase.BUILD:
        return this.productionSchedulePlan.canVerify();
      case ProductionSchedulePhase.VERIFY:
        return !this.productionSchedulePlanComponent?.isVerifyingProductionSchedule && this.canConfirmProductionSchedule();
      default:
        return false;
    }
  }

  private navigateBackFromVerify(): void {
    this.pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit =
      this.productionScheduleToAdd.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit;
    this.pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks =
      this.productionScheduleToAdd.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks;
    this.productionSchedulePlanComponent.back();
  }

  private createProductionSchedule(nextPhase: ProductionSchedulePhase, isMachineQualityChanged: boolean = false, routeToNextRoute: boolean = true): void {
    const practicalPickDensityUnit: Unit = Unit[this.getDefaultParameterForPropertyNameOfMachineQualitySettings('pickDensityUnit').propertyValue as string];
    const practicalPickDensityInPicksInMM = convertUnit({
      from: {
        value: this.productionScheduleInitialPreparationsForm.get('practicalPickDensity').value,
        unit: practicalPickDensityUnit
      },
      to: Unit.PICKS_PER_MILLIMETER
    });

    this.productionSchedules
      .createProductionSchedule(
        this.productionScheduleToAdd.machine,
        this.productionScheduleToAdd.machineQuality,
        practicalPickDensityInPicksInMM,
        this.productionScheduleToAdd.coloredYarnSets,
        this.productionScheduleToAdd.creel,
        this.productionScheduleToAdd.runId,
        this.productionScheduleToAdd.mappingForCurrentPositionOnMachine,
        this.productionScheduleInitialPreparationsForm.value.productConfiguration,
        this.productionScheduleToAdd.isSample,
        this.productionScheduleToAdd.canAddJute,
        this.productionScheduleToAdd.hmiVersion,
        this.productionScheduleToAdd.addToPlanboard,
        this.preselectionListId
      )
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productionSchedule: ProductionSchedule) => {
        this.productionScheduleToAdd.id = productionSchedule.id;
        this.productionScheduleToAdd.coloredYarnSets = productionSchedule.coloredYarnSets;
        this.productionScheduleToAdd.dateCreated = new Date();
        this.productionScheduleToAdd.preselectionId = productionSchedule.preselectionId;
        this.preselectionListId = productionSchedule.preselectionId;

        if (this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.length > 0) {
          this.initialiseProductionSchedulePathsOfColoredYarnSets(productionSchedule);
        }

        this.initialPreparationsCompleted(nextPhase, isMachineQualityChanged, routeToNextRoute);
      });
  }

  private navigateToEditProductionSchedule(id: string, phase: ProductionSchedulePhase): void {
    this.router.navigateByUrl(RouteUtils.paths.texFab.productionOrder.editProductionOrderNewView.absolutePath.replace(':id', id), {
      state: {phase, startRoute: this.addProductionScheduleNavigationState.startRoute}
    });
  }

  private getPreselectedOrderlinesAndDrawings(): void {
    this.productionSchedules
      .getPreselectionListById(this.preselectionListId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((preselectionParameters: ParametersForPreselection) => {
        this.preselectedOrderlineIds = preselectionParameters.orderlinesIds;
        this.preselectedDesignIds = preselectionParameters.designIds;
        this.preselectedConfigurationIds = preselectionParameters.configurationIds;
        this.getPreselectedDrawings();
      });
  }

  private getPreselectedDrawings(): void {
    const endRow = Math.max(this.preselectedDesignIds?.length ?? 0, this.preselectedConfigurationIds?.length ?? 0);
    const startRow = 0;
    const sortModel = [];
    let filterModel: any = this.drawingLibraryHelper.getPreselectedDrawingAndConfigurationIdsFilterModel(this.preselectedDesignIds, this.preselectedConfigurationIds);

    if (!AssertionUtils.isNullOrUndefined(filterModel)) {
      const gridModel = new GridModel(startRow, endRow, sortModel, filterModel);

      this.drawings
        .getAllWithoutImageDataFromGridModel(TargetForListOfDrawingsEnum.PRODUCTION_ORDER, gridModel, true)
        .pipe(takeUntil(this.unSubscribeOnViewDestroy))
        .subscribe((listOfDrawings: Drawing[]) => {
          this.preselectedDrawings = listOfDrawings.map((drawing: Drawing) => new ListDrawingConfiguration(drawing, 0));
        });
    }
  }

  private initialiseProductionSchedulePathsOfColoredYarnSets(productionSchedule: ProductionSchedule): void {
    this.productionSchedules
      .setProductionSchedulePlan(`${this.productionScheduleToAdd.id}`, this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets, productionSchedule.machineQuality.reedDensity * 1000)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe();
  }

  private initialPreparationsCompleted(nextPhase: ProductionSchedulePhase, isMachineQualityChanged: boolean = false, routeToNextRoute: boolean = true): void {
    this.saveButtonClicked = true;
    forkJoin([
      this.machineQualities.getPathWidths(this.productionScheduleToAdd.machineQuality.id),
      this.productionSchedules.updateWithMachineQualityChanged(this.productionScheduleToAdd, isMachineQualityChanged)
    ])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([pathWidths]: [PathWidth[], void]) => {
          this.pathWidths = pathWidths;

          const requests: Observable<ProductionSchedule | void>[] = [this.productionSchedules.getById(this.productionScheduleToAdd.id)];

          forkJoin(requests)
            .pipe(takeUntil(this.unSubscribeOnViewDestroy))
            .subscribe({
              next: ([productionSchedule]: [ProductionSchedule]) => {
                this.productionScheduleToAdd.warnings = productionSchedule.warnings;
                this.productionScheduleToAdd.errors = productionSchedule.errors;
                this.productionScheduleToAdd.coloredYarnSets = productionSchedule.coloredYarnSets;
                if (productionSchedule.coloredYarnSets.length === productionSchedule.productionSchedulePathsOfColoredYarnSets.length) {
                  this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets = productionSchedule.productionSchedulePathsOfColoredYarnSets;
                }

                if (nextPhase !== ProductionSchedulePhase.VERIFY) {
                  this.productionScheduleToAdd.removePathLabelsAndNonProductionItemsFromProductionSchedulePathsOfColoredYarnSets();
                }

                if (!this.isEditingProductionOrder()) {
                  this.addProductionSchedule.notifyChangesToInitialPreparationsForm(false);

                  this.toastService.showInfo({
                    tapToDismiss: false,
                    timeOut: 2000,
                    message: this.translate.instant('GENERAL.ACTIONS.CREATED_OBJECT', {
                      object: this.translate.instant('PRODUCTION_ORDER.PRODUCTION_ORDER', {count: 1}),
                      name: '',
                      count: 1
                    })
                  });

                  this.productionSchedulePlan.disableUnsavedChanges();

                  this.justInitialized = true;
                  if (routeToNextRoute) {
                    this.navigateToEditProductionSchedule(`${this.productionScheduleToAdd.id}`, nextPhase);
                  }
                }

                if (routeToNextRoute) {
                  this.setProductionSchedulePhase(nextPhase);
                }

                this.isRunningInitialPreparations = false;
                this.isRunningInitialPreparationsToDesigns = false;
                this.canLeavePageAfterCheckingGuard.next(true);
              },
              error: this.stopRunningInitialPreparationsAndThrowError()
            });
        },
        error: this.stopRunningInitialPreparationsAndThrowError()
      });
  }

  private initialiseAddProductionSchedule(): void {
    forkJoin([this.machineQualities.getListOfCustomSettings(), this.productionOrders.getListOfCustomSettings(), this.productionOrders.hasAlreadyAdjustedCustomSettings()])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([listOfMachineQualityCustomSettings, listOfProductionOrdersCustomSettings, alreadyAdjustedDefaultParameters]: [PropertyValue[], PropertyValue[], boolean]) => {
          this.listOfMachineQualityCustomSettings = listOfMachineQualityCustomSettings;

          this.customSettingsForProductionScheduleChosen(null, listOfProductionOrdersCustomSettings);

          this.alreadySetUpDefaultParameters = alreadyAdjustedDefaultParameters;

          const emptyProductionSchedulePhaseState = {productionSchedulePhase: null} as ProductionSchedulePhaseData;

          const productionSchedulePhaseState = this.navigationHelperService.getPartialState<ProductionSchedulePhaseData>(Object.keys(emptyProductionSchedulePhaseState), true);

          const preselectionState: {preselectionId?: number; goToPreselection?: boolean} = this.navigationHistory.getState();
          const emptyProductionScheduleState = {productionSchedule: null} as ProductionScheduleData;
          const productionScheduleState = this.navigationHelperService.getPartialState<ProductionScheduleData>(Object.keys(emptyProductionScheduleState), true);
          this.hasProductionScheduleState = !AssertionUtils.isNullOrUndefined(productionScheduleState) && !ObjectUtils.isDeepEqual(emptyProductionScheduleState, productionScheduleState);

          if (this.isCreatingNewProductionSchedule()) {
            this.initialiseNewProductionSchedule(preselectionState, productionScheduleState, productionSchedulePhaseState?.productionSchedulePhase as ProductionSchedulePhase);
          } else {
            this.initialiseExistingProductionSchedule(
              this.hasProductionScheduleState ? productionScheduleState.productionSchedule : null,
              productionSchedulePhaseState?.productionSchedulePhase as ProductionSchedulePhase
            );
          }
        },
        error: this.navigateToOverviewAndThrowError()
      });

    this.productionSchedulePlan.currentUpdatedProductionSchedule.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe((productionSchedule: ProductionSchedule) => {
      this.productionScheduleToAdd = productionSchedule;

      this.setProductionScheduleInitialPreparationsFormFieldsValues(productionSchedule);
    });
  }

  private initialiseNewProductionSchedule(preselectionState: any, productionScheduleState: any, productionSchedulePhaseState: ProductionSchedulePhase): void {
    if ((!preselectionState.goToPreselection && !AssertionUtils.isNullOrUndefined(preselectionState.goToPreselection)) || productionSchedulePhaseState === ProductionSchedulePhase.CONFIGURE) {
      this.preselectionListId = productionScheduleState.productionSchedule.preselectionId;
      if (!AssertionUtils.isNullOrUndefined(this.preselectionListId)) {
        this.getPreselectedOrderlinesAndDrawings();
      }
      this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
    } else {
      this.currentProductionSchedulePhase = ProductionSchedulePhase.PRESELECTION;
    }

    if (this.isAddingProductionOrderForProductConfiguration()) {
      this.currentProductionSchedulePhase = ProductionSchedulePhase.CONFIGURE;
      this.makePreselectionListFromProductConfiguration();
    }

    if (this.hasProductionScheduleState) {
      this.productionScheduleToAdd = productionScheduleState.productionSchedule;
      this.setProductionScheduleInitialPreparationsFormFieldsValues(this.productionScheduleToAdd);
    } else {
      this.productionScheduleToAdd = ProductionSchedule.createEmptyProductionSchedule();
    }

    this.isLoadingProductionSchedule = false;
  }

  private makePreselectionListFromProductConfiguration(): void {
    const productConfigurationId: number = this.activatedRoute.snapshot.params.id;

    this.productConfigurations
      .getById(productConfigurationId)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe((productConfiguration: ProductConfiguration) => {
        this.preselectedDesignIds = productConfiguration.designs.map((design: Drawing) => design.id);
        this.createNewPreselectedOrderlinesAndDrawingsList();
      });
  }

  private setProductionScheduleInitialPreparationsFormFieldsValues(productionSchedule: ProductionSchedule): void {
    const emptyProductionScheduleNameState = {name: null} as ProductionScheduleNameData;
    const productionScheduleNameState = this.navigationHelperService.getPartialState<ProductionScheduleNameData>(Object.keys(emptyProductionScheduleNameState));
    const hasProductionScheduleNameState = !AssertionUtils.isNullOrUndefined(productionScheduleNameState) && !ObjectUtils.isDeepEqual(emptyProductionScheduleNameState, productionScheduleNameState);

    this.productionScheduleInitialPreparationsForm?.patchValue({
      name: hasProductionScheduleNameState ? productionScheduleNameState.name : productionSchedule.name,
      machine: productionSchedule.machine,
      machineQuality: productionSchedule.machineQuality,
      practicalPickDensity: productionSchedule.practicalPickDensityInPicksPerMM,
      productConfiguration: productionSchedule.productConfiguration,
      creel: productionSchedule.creel
    });

    if (hasProductionScheduleNameState) {
      this.productionScheduleInitialPreparationsForm.controls.name.markAsTouched();
    }
  }

  private setProductionScheduleInitialPreparationsFormFields(): void {
    this.productionScheduleInitialPreparationsForm = this.formBuilder.group({
      name: this.formBuilder.control(null, [Validators.required, Validators.pattern('^[A-Za-z0-9_]*$'), Validators.maxLength(this.MAX_LENGTH_FOR_PRODUCTION_ORDER_NAME)]),
      machine: this.formBuilder.control(null, Validators.required),
      machineQuality: this.formBuilder.control(null, Validators.required),
      practicalPickDensity: this.formBuilder.control(null, [Validators.required, Validators.min(1)]),
      productConfiguration: this.formBuilder.control(null),
      creel: this.formBuilder.control(null, Validators.required),
      buggy: this.formBuilder.control(null)
    });
  }

  private setPathContentSettingsDialogData(): void {
    const pathContentPanelEnabled = this.localStorage.get(this.localstoragePathContentPanelEnabledKey);
    this.pathContentSettingsDialogData.pathContentPanelEnabled = pathContentPanelEnabled !== null ? !!pathContentPanelEnabled : true;
  }

  private initialiseExistingProductionSchedule(productionScheduleState: ProductionSchedule, productionSchedulePhaseState: ProductionSchedulePhase): void {
    const isDuplicatingProductionSchedule = this.isCurrentRouteDuplicateProductionScheduleRoute();
    const getProductionScheduleObservable = isDuplicatingProductionSchedule
      ? this.productionSchedules.getDuplicatedProductionOrder(this.activatedRoute.snapshot.params.id)
      : this.productionSchedules.getById(this.activatedRoute.snapshot.params.id);

    this.isLoadingProductionSchedule = true;

    if (productionScheduleState) {
      this.setExistingProductionSchedule(productionScheduleState, productionSchedulePhaseState);
    } else {
      getProductionScheduleObservable.pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe({
        next: (productionSchedule: ProductionSchedule) => {
          this.pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks =
            productionSchedule.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInPicks;
          this.pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit =
            productionSchedule.getPathLabelsOrNonProductionItemsLengthInPicksFromLongestPath().pathLabelsOrNonProductionItemsFromLongestPathLengthInCommercialUnit;

          this.preselectionListId = productionSchedule.preselectionId;
          if (!AssertionUtils.isNullOrUndefined(this.preselectionListId)) {
            this.getPreselectedOrderlinesAndDrawings();
          }
          this.setExistingProductionSchedule(productionSchedule, productionSchedulePhaseState);
        },
        error: this.navigateToOverviewAndThrowError()
      });
    }
  }

  private setExistingProductionSchedule(productionSchedule: ProductionSchedule, productionSchedulePhaseState?: ProductionSchedulePhase): void {
    const isDuplicatingProductionSchedule = this.isCurrentRouteDuplicateProductionScheduleRoute();

    forkJoin([
      this.productionSchedules.getDesignsAndOrderLines(isDuplicatingProductionSchedule ? this.activatedRoute.snapshot.params.id : productionSchedule.id, productionSchedule.status),
      this.machineQualities.getPathWidths(productionSchedule.machineQuality.id)
    ])
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: ([{designs, orderLines}, pathWidths]: [{designs: Drawing[]; orderLines: ProductionScheduleOrderLine[]}, PathWidth[]]) => {
          productionSchedule.designs = designs;
          productionSchedule.orderLines = orderLines;

          productionSchedule.updateOrderLineQuantities();
          this.pathWidths = pathWidths;

          if (isDuplicatingProductionSchedule) {
            this.duplicateProductionSchedule(productionSchedule);
          } else {
            this.productionScheduleInitialised(productionSchedule, productionSchedulePhaseState);
          }
        },
        error: this.navigateToOverviewAndThrowError()
      });
  }

  private isCurrentRouteEditProductionScheduleRoute(): boolean {
    return this.activatedRoute.snapshot.routeConfig?.path === AddProductionScheduleNewPageComponent.EDIT_PRODUCTION_SCHEDULE_URL;
  }

  private isCurrentRouteDuplicateProductionScheduleRoute(): boolean {
    return this.activatedRoute.snapshot.routeConfig.path === AddProductionScheduleNewPageComponent.DUPLICATE_PRODUCTION_SCHEDULE_URL;
  }

  private customSettingsForProductionScheduleChosen(adjusted: boolean, listOfParametersForDefaultParameters?: PropertyValue[]): void {
    if (!AssertionUtils.isNullOrUndefined(listOfParametersForDefaultParameters)) {
      this.listOfParametersForDefaultParameters = listOfParametersForDefaultParameters;
      this.alreadySetUpDefaultParameters = adjusted;
    } else {
      if (!this.alreadySetUpDefaultParameters) {
        this.router.navigateByUrl(AddProductionScheduleNewPageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL);
      }
    }
  }

  private updateProducedAmount(): void {
    if (this.productionScheduleToAdd.completingPartially) {
      this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          productionSchedulePath.pathGroups.forEach((pathGroup: ProductionScheduleItemInPathGroup) => {
            if (AssertionUtils.isNullOrUndefined(pathGroup.amountProduced)) {
              pathGroup.amountProduced = pathGroup.quantity;
            }
          });
        });
      });
    }
  }

  private updateProductionScheduleItemInPathGroupStatus(): void {
    if (this.productionScheduleToAdd.completingPartially) {
      this.productionScheduleToAdd.productionSchedulePathsOfColoredYarnSets.forEach((productionSchedulePathsOfColoredYarnSet: ProductionSchedulePathsOfColoredYarnSet) => {
        productionSchedulePathsOfColoredYarnSet.productionSchedulePaths.forEach((productionSchedulePath: ProductionSchedulePath) => {
          productionSchedulePath.pathGroups.forEach((pathGroup: ProductionScheduleItemInPathGroup) => {
            if (AssertionUtils.isNullOrUndefined(pathGroup.status)) {
              pathGroup.status = ProductionScheduleItemInPathGroupStatus.NORMAL;
            }
          });
        });
      });
    }
  }

  private navigateToOverviewAndThrowError(): (error: any) => void {
    return ErrorHandlers.navigateToOverviewAndThrowError(this.router, AddProductionScheduleNewPageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL, this.navigationHelperService);
  }

  private stopRunningInitialPreparationsAndThrowError(): (error: UnhandledBackendError) => void {
    return (error: UnhandledBackendError): void => {
      this.isRunningInitialPreparations = false;
      this.isRunningInitialPreparationsToDesigns = false;
      throw error;
    };
  }

  private duplicateProductionSchedule(productionSchedule: ProductionSchedule): void {
    productionSchedule.id = null;
    productionSchedule.name = '';
    productionSchedule.runId = null;

    this.productionSchedules
      .duplicateProductionOrder(productionSchedule)
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe({
        next: (duplicatedProductionSchedule: ProductionSchedule) => {
          this.productionScheduleInitialPreparationsForm.markAsDirty();
          this.productionScheduleInitialised(duplicatedProductionSchedule);
        },
        error: this.navigateToOverviewAndThrowError()
      });
  }

  private navigateToPreviousRoute(): void {
    if (this.isCreatingNewProductionSchedule() && !AssertionUtils.isNullOrUndefined(this.productionScheduleToAdd.id)) {
      this.productionSchedules.cancelTemporarySavedChanges(this.productionScheduleToAdd.id.toString()).pipe(takeUntil(this.unSubscribeOnViewDestroy)).subscribe();
    }
    if (this.isAddingProductionOrderForProductConfiguration()) {
      this.router.navigate([RouteUtils.paths.texEdit.editProductConfiguration.absolutePath.replace('/:id', ''), this.activatedRoute.snapshot.params.id]);
    } else {
      const previousRoute = !AssertionUtils.isNullOrUndefined(this.addProductionScheduleNavigationState?.startRoute)
        ? this.addProductionScheduleNavigationState.startRoute
        : AddProductionScheduleNewPageComponent.PRODUCTION_SCHEDULE_OVERVIEW_URL;
      this.navigationHelperService.navigateToPreviousRoute(previousRoute);
    }
  }

  private getRouteStateProperties(): void {
    this.activatedRoute.paramMap?.subscribe(() => {
      if (history.state) {
        this.addProductionScheduleNavigationState = history.state as AddProductionScheduleNavigationState;
      }
    });
  }
}
