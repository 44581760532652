import {Component, ComponentRef, Injector, OnInit, Optional, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs';
import {BaseComponent} from '../../base-component';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {CrudObjectSelectionDialogComponent} from '../crud-overview-data/crud-object-selection-dialog/crud-object-selection-dialog.component';
import {ContentSwitcherDialogPrototypeService} from './content-switcher-dialog-prototype.service';

@Component({
  templateUrl: './content-switcher-dialog-prototype.component.html',
  styleUrls: ['./content-switcher-dialog-prototype.component.scss']
})
export class ContentSwitcherDialogPrototypeComponent extends BaseComponent implements OnInit {
  @ViewChild('contentSwitcherContainer', {read: ViewContainerRef, static: true}) public contentSwitcherContainer;

  protected injector: Injector;

  public get showHeader(): boolean {
    return this.contentSwitcher?.activeEntry?.componentInfo?.component !== CrudObjectSelectionDialogComponent;
  }

  public constructor(
    @Optional() private readonly dialog: MatDialogRef<any>,
    protected readonly contentSwitcher: ContentSwitcherDialogPrototypeService
  ) {
    super();
  }

  public canShowBackButton(): boolean {
    return this.contentSwitcher.navigationHistory.length >= 1;
  }

  public getContentSwitcherHeaderTitle(): string {
    if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher?.activeEntry?.componentInfo.entityName)) {
      return this.contentSwitcher.activeEntry.componentInfo.entityName;
    } else {
      return this.contentSwitcher?.headerTitle ?? '';
    }
  }

  public ngOnInit(): void {
    this.contentSwitcher.dialog = this.dialog;

    this.dialog
      ?.beforeClosed()
      .pipe(takeUntil(this.unSubscribeOnViewDestroy))
      .subscribe(() => this.contentSwitcher.reset());

    this.injector = Injector.create({providers: [{provide: MatDialogRef, useValue: this.dialog}], parent: this.contentSwitcher.parentInjector});

    this.contentSwitcher.navigateForwardComponent.subscribe((component: Type<any> | null) => {
      if (!AssertionUtils.isNullOrUndefined(component)) {
        if (this.contentSwitcher.hasPreviousContainerRef()) {
          this.contentSwitcherContainer.detach();
        }

        this.contentSwitcher.activeEntry.componentRef = this.contentSwitcherContainer.createComponent(component, {injector: this.injector});

        if (!AssertionUtils.isNullOrUndefined(this.contentSwitcher.activeEntry.componentInfo.componentInputs)) {
          Object.assign(this.contentSwitcher.activeEntry.componentRef.instance, this.contentSwitcher.activeEntry.componentInfo.componentInputs);
        }
      }
    });

    this.contentSwitcher.navigateBackComponent.subscribe((componentRef: ComponentRef<any>) => {
      this.contentSwitcherContainer.clear();

      this.contentSwitcherContainer.insert(componentRef.hostView);
    });
  }
}
