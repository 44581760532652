import {Params, PRIMARY_OUTLET, Route, Router, UrlSegment} from '@angular/router';
import {ArticleRoute} from '@application/helper/routing/article-route';
import {ColorRoute} from '@application/helper/routing/color-route';
import {ColorSetRoute} from '@application/helper/routing/color-set-route';
import {ColoredYarnSetRoute} from '@application/helper/routing/colored-yarn-set-route';
import {CreelRoute} from '@application/helper/routing/creel-route';
import {FinishingTemplateRoute} from '@application/helper/routing/finishing-template-route';
import {MachineQualityRoute} from '@application/helper/routing/machine-quality-route';
import {RootRoute} from '@application/helper/routing/root-route';
import {RouteLeaf} from '@application/helper/routing/route-leaf';
import {SalesOrderRoute} from '@application/helper/routing/sales-order-route';
import {TexBiRoute} from '@application/helper/routing/tex-bi-route';
import {TexBotRoute} from '@application/helper/routing/tex-bot-route';
import {TexEditRoute} from '@application/helper/routing/tex-edit-route';
import {TexFabRoute} from '@application/helper/routing/tex-fab-route';
import {TexStateRoute} from '@application/helper/routing/tex-state-route';
import {TexStyleRoute} from '@application/helper/routing/tex-style-route';
import {YarnRoute} from '@application/helper/routing/yarn-route';
import {YarnSetRoute} from '@application/helper/routing/yarn-set-route';
import {Permission} from '@domain/profile/permission.enum';
import {AssertionUtils} from '@vdw/angular-component-library';
import {isEmpty, keys} from 'lodash-es';
import {AlertRoute} from './alert-route';
import {BuggyRoute} from './buggy-route';
import {CalendarRoute} from './calendar-route';
import {ColoredYarnRoute} from './colored-yarn-route';
import {CompanyWeaveStructureRoute} from './company-weave-structure-route';
import {CustomerRoute} from './customer-route';
import {DataUnitSetupCatalogRoute} from './data-unit-setup-catalog-route';
import {DataUnitSetupRoute} from './data-unit-setup-route';
import {DeclarationRoute} from './declaration-route';
import {DeviceTemplateCatalogRoute} from './device-template-catalog-route';
import {DeviceTemplateRoute} from './device-template-route';
import {EventRoute} from './event-route';
import {FixedScheduleRoute} from './fixed-schedule-route';
import {GroupedWeaveStructureRoute} from './grouped-weave-structure-route';
import {MachineDataSourceRoute} from './machine-data-sources.route';
import {OperatorRoute} from './operator-route';
import {OrderbookRoute} from './orderbook-route';
import {PathLayoutTemplateRoute} from './path-layout-template.route';
import {PhysicalQuantityRoute} from './physical-quantity-route';
import {PlanningPrototypeRoute} from './planning-prototype-route';
import {PlanningRoute} from './planning-route';
import {PlasticProductRoute} from './plastic-product-route';
import {PlasticRoute} from './plastic-route';
import {ProductionOrderRoute} from './production-order.route';
import {ProfileRoute} from './profile-route';
import {RejectReasonRoute} from './reject-reason-route';
import {ReportItemRoute} from './report-item-route';
import {ResourceTypeRoute} from './resource-type-route';
import {SettingsRoute} from './settings-route';
import {ShiftRoute} from './shift-route';
import {TexBotMachineDocumentationRoute} from './tex-bot-machine-documentation-route';
import {TexBotTrainingRoute} from './tex-bot-training-route';
import {ToolRoute} from './tool-route';
import {TuftProductRoute} from './tuft-product-route';
import {TuftingRoute} from './tufting-route';
import {UtilitiesRoute} from './utilities-route';
import {WeaveProductRoute} from './weave-product-route';
import {WeaveStructureRoute} from './weave-structure-route';
import {WeavingRoute} from './weaving.route';
import {WeftColoredYarnSetRoute} from './weft-colored-yarn-set-route';

export class RouteUtils {
  public static readonly paths: RootRoute = RouteUtils.initialize();

  public static buildPathFromSegmentsAndQueryParams(segments: UrlSegment[], params: Params): string {
    let path = segments.join('/');

    if (!isEmpty(params)) {
      const queryStrings = keys(params).map((key: string) => `${key}=${params[key]}`);
      path = `${path}?${queryStrings.join('&')}`;
    }
    return path;
  }

  public static isKnownPath(pathSegments: string[]): boolean {
    return RouteUtils.paths.isKnownPath(pathSegments);
  }

  public static getParentRoute(path: string, router: Router): Route {
    const tree = router.parseUrl(path);
    const urlSegmentGroup = tree.root.children[PRIMARY_OUTLET];

    return router.config.find((route: Route) => route.path.includes(urlSegmentGroup.segments[0].toString()));
  }

  public static isRoutePathMatching(routeA: string, routeB: string): boolean {
    if (AssertionUtils.isNullOrUndefined(routeA) || AssertionUtils.isNullOrUndefined(routeB)) {
      return false;
    }

    const patternParts = routeB
      .replace(/:\w+/g, '*')
      .split('/')
      .map((part: string) => (part === '*' ? '.*' : part));

    return new RegExp(`^${patternParts.join('\\/')}$`).test(window?.location?.origin ? routeA.replace(window.location.origin, '') : routeA);
  }

  private static initialize(): RootRoute {
    const rootRoute = new RootRoute('');

    rootRoute.login = new RouteLeaf('login');

    const texEdit = new TexEditRoute('texedit', Permission.TEXEDIT_SHOW_ICON, false);
    texEdit.dataSheets = new RouteLeaf('datasheets', Permission.TEXEDIT_TUFTLINK);
    texEdit.dataSheetDetails = new RouteLeaf('datasheets/:id', Permission.TEXEDIT_TUFTLINK);
    texEdit.designLibrary = new RouteLeaf('design-library', Permission.TEXEDIT_VIEW_DRAWING_LIBRARY);
    texEdit.designLibrarySettings = new RouteLeaf('design-library/settings', Permission.TEXSTYLE_VIEW);
    texEdit.addDesign = new RouteLeaf('design-library/add', Permission.TEXEDIT_EDIT_DRAWING_LIBRARY);
    texEdit.productConfiguration = new RouteLeaf('product-configuration', Permission.TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW);
    texEdit.addProductConfiguration = new RouteLeaf('product-configuration/add', Permission.TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW);
    texEdit.editProductConfiguration = new RouteLeaf('product-configuration/edit/:id', Permission.TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW);
    texEdit.duplicateProductConfiguration = new RouteLeaf('product-configuration/add/:id', Permission.TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW);
    texEdit.productConfigurationSettings = new RouteLeaf('product-configuration/settings', Permission.TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW);
    texEdit.tuftLink = new RouteLeaf('tuft-link', Permission.TEXEDIT_TUFTLINK);
    rootRoute.texEdit = texEdit;

    const texState = new TexStateRoute('texstate', Permission.TEXSTATE_SHOW_ICON, true);
    texState.settings = new RouteLeaf('settings');
    texState.addTemplate = new RouteLeaf('settings/template/add', Permission.TEXSTATE_EDIT_DYNAMIC_DATA_TEMPLATE);
    texState.editTemplate = new RouteLeaf('settings/template/edit/:id', Permission.TEXSTATE_VIEW_DYNAMIC_DATA_TEMPLATE);
    texState.duplicateTemplate = new RouteLeaf('settings/template/add/:id', Permission.TEXSTATE_EDIT_DYNAMIC_DATA_TEMPLATE);
    texState.editMachine = new RouteLeaf('edit/:machineType/:id', Permission.TEXSTATE_EDIT_MACHINE);
    texState.kiosk = new RouteLeaf('kiosk');
    texState.addWeavingMachineType = new RouteLeaf('weaving-machine-type/add');
    texState.addPlasticMachineType = new RouteLeaf('plastic-machine-type/add');
    texState.addTuftingMachineType = new RouteLeaf('tufting-machine-type/add');
    texState.addWinderMachineType = new RouteLeaf('winder-machine-type/add');
    texState.addDyeingMachineType = new RouteLeaf('dyeing-machine-type/add');
    texState.prototype = new RouteLeaf('prototype', Permission.TEXSTATE_VIEW_DYNAMIC_DATA_TEMPLATE);
    rootRoute.texState = texState;

    const texBi = new TexBiRoute('texbi', Permission.TEXBI_SHOW_ICON, false);
    texBi.reports = new RouteLeaf('reports', Permission.TEXBI_READ_A_REPORT);
    texBi.bmsreports = new RouteLeaf('bms-reports-page');
    texBi.windervibrationreport = new RouteLeaf('bms-reports-page/windervibration', Permission.TEXBI_EXTRUSION_VIBRATION_REPORT);
    texBi.qmreports = new RouteLeaf('bms-reports-page/qualimaster', Permission.ACCESS_ALL);
    texBi.wmreports = new RouteLeaf('bms-reports-page/weavemaster', Permission.ACCESS_ALL);
    texBi.emreports = new RouteLeaf('bms-reports-page/energymaster', Permission.ACCESS_ALL);
    texBi.machineEventBoard = new RouteLeaf('machine-event-board', Permission.TEXBI_EVENT_BOARD_VIEW);
    texBi.winderVibrationForm = new RouteLeaf('winder-vibration-form', Permission.TEXBI_EXTRUSION_VIBRATION_FORM);
    rootRoute.texBi = texBi;

    const profile = new ProfileRoute('profile', null, true);
    profile.changelogMobile = new RouteLeaf('changelog-mobile', null, true);
    rootRoute.profile = profile;

    rootRoute.help = new RouteLeaf('help', null, true);
    rootRoute.unauthorized = new RouteLeaf('unauthorized');
    rootRoute.notFound = new RouteLeaf('not-found');
    rootRoute.credits = new RouteLeaf('credits', Permission.MANAGEMENT_APPLICATION, false);

    const texStyle = new TexStyleRoute('texstyle', Permission.TEXSTYLE_SHOW_ICON, false);

    const finishingTemplate = new FinishingTemplateRoute('finishing-template', Permission.TEXSTYLE_FINISHING_VIEW);
    finishingTemplate.addFinishingTemplate = new RouteLeaf('add', Permission.TEXSTYLE_FINISHING_EDIT);
    finishingTemplate.duplicateFinishingTemplate = new RouteLeaf('add/:id', Permission.TEXSTYLE_FINISHING_EDIT);
    finishingTemplate.editFinishingTemplate = new RouteLeaf('edit/:id', Permission.TEXSTYLE_FINISHING_VIEW);
    texStyle.finishingTemplate = finishingTemplate;

    const color = new ColorRoute('color', Permission.TEXSTYLE_COLOR_VIEW);
    color.addColor = new RouteLeaf('add', Permission.TEXSTYLE_COLOR_EDIT);
    color.duplicateColor = new RouteLeaf('add/:id', Permission.TEXSTYLE_COLOR_EDIT);
    color.editColor = new RouteLeaf('edit/:id', Permission.TEXSTYLE_COLOR_VIEW);
    color.colorSettings = new RouteLeaf('settings', Permission.TEXSTYLE_COLOR_EDIT);
    texStyle.color = color;

    const colorSet = new ColorSetRoute('color-set', Permission.TEXSTYLE_COLORSET_VIEW);
    colorSet.addColorSet = new RouteLeaf('add', Permission.TEXSTYLE_COLORSET_EDIT);
    colorSet.duplicateColorSet = new RouteLeaf('add/:id', Permission.TEXSTYLE_COLORSET_EDIT);
    colorSet.editColorSet = new RouteLeaf('edit/:id', Permission.TEXSTYLE_COLORSET_VIEW);
    colorSet.colorSetSettings = new RouteLeaf('settings', Permission.TEXSTYLE_COLORSET_EDIT);
    texStyle.colorSet = colorSet;

    const yarnSet = new YarnSetRoute('yarn-set', Permission.TEXSTYLE_YARNSET_VIEW);
    yarnSet.addYarnSet = new RouteLeaf('add', Permission.TEXSTYLE_YARNSET_EDIT);
    yarnSet.duplicateYarnSet = new RouteLeaf('add/:id', Permission.TEXSTYLE_YARNSET_EDIT);
    yarnSet.editYarnSet = new RouteLeaf('edit/:id', Permission.TEXSTYLE_YARNSET_VIEW);
    yarnSet.yarnSetSettings = new RouteLeaf('settings', Permission.TEXSTYLE_YARNSET_EDIT);
    texStyle.yarnSet = yarnSet;

    const coloredYarnSet = new ColoredYarnSetRoute('colored-yarn-set', Permission.TEXSTYLE_COLOREDYARNSET_VIEW);
    coloredYarnSet.addColoredYarnSet = new RouteLeaf('add', Permission.TEXSTYLE_COLOREDYARNSET_EDIT);
    coloredYarnSet.duplicateColoredYarnSet = new RouteLeaf('add/:id', Permission.TEXSTYLE_COLOREDYARNSET_EDIT);
    coloredYarnSet.editColoredYarnSet = new RouteLeaf('edit/:id', Permission.TEXSTYLE_COLOREDYARNSET_VIEW);
    texStyle.coloredYarnSet = coloredYarnSet;

    const weftColoredYarnSet = new WeftColoredYarnSetRoute('weft-colored-yarn-set', Permission.TEXSTYLE_WEFTCOLOREDYARNSET_VIEW);
    weftColoredYarnSet.addWeftColoredYarnSet = new RouteLeaf('add', Permission.TEXSTYLE_WEFTCOLOREDYARNSET_EDIT);
    weftColoredYarnSet.duplicateWeftColoredYarnSet = new RouteLeaf('add/:id', Permission.TEXSTYLE_WEFTCOLOREDYARNSET_EDIT);
    weftColoredYarnSet.editWeftColoredYarnSet = new RouteLeaf('edit/:id', Permission.TEXSTYLE_WEFTCOLOREDYARNSET_VIEW);
    texStyle.weftColoredYarnSet = weftColoredYarnSet;

    const coloredYarn = new ColoredYarnRoute('colored-yarn', Permission.ACCESS_ALL);
    coloredYarn.addColoredYarn = new RouteLeaf('add', Permission.ACCESS_ALL);
    coloredYarn.duplicateColoredYarn = new RouteLeaf('add/:id', Permission.ACCESS_ALL);
    coloredYarn.editColoredYarn = new RouteLeaf('edit/:id', Permission.ACCESS_ALL);
    texStyle.coloredYarn = coloredYarn;

    const yarn = new YarnRoute('yarn', Permission.TEXSTYLE_YARN_VIEW);
    yarn.addYarn = new RouteLeaf('add', Permission.TEXSTYLE_YARN_EDIT);
    yarn.duplicateYarn = new RouteLeaf('add/:id', Permission.TEXSTYLE_YARN_EDIT);
    yarn.editYarn = new RouteLeaf('edit/:id', Permission.TEXSTYLE_YARN_VIEW);
    texStyle.yarn = yarn;

    const fixedSchedule = new FixedScheduleRoute('fixed-schedule', Permission.FIXED_SCHEDULE_VIEW);
    fixedSchedule.addFixedSchedule = new RouteLeaf('add', Permission.FIXED_SCHEDULE_EDIT);
    fixedSchedule.duplicateFixedSchedule = new RouteLeaf('add/:id', Permission.FIXED_SCHEDULE_EDIT);
    fixedSchedule.editFixedSchedule = new RouteLeaf('edit/:id', Permission.FIXED_SCHEDULE_EDIT);
    texStyle.fixedSchedule = fixedSchedule;

    const machineQuality = new MachineQualityRoute('machine-quality', Permission.TEXSTYLE_MACHINE_QUALITY_VIEW);
    machineQuality.addMachineQuality = new RouteLeaf('add', Permission.TEXSTYLE_MACHINE_QUALITY_EDIT);
    machineQuality.duplicateMachineQuality = new RouteLeaf('add/:id', Permission.TEXSTYLE_MACHINE_QUALITY_EDIT);
    machineQuality.editMachineQuality = new RouteLeaf('edit/:id', Permission.TEXSTYLE_MACHINE_QUALITY_VIEW);
    machineQuality.addFinishing = new RouteLeaf('finishing/add', Permission.TEXSTYLE_FINISHING_EDIT);
    machineQuality.addFinishingFromQuality = new RouteLeaf('edit/:qualityId/finishing/add', Permission.TEXSTYLE_FINISHING_EDIT);
    machineQuality.duplicateFinishing = new RouteLeaf('finishing/add/:id', Permission.TEXSTYLE_FINISHING_EDIT);
    machineQuality.editFinishing = new RouteLeaf('finishing/edit/:id', Permission.TEXSTYLE_FINISHING_VIEW);
    machineQuality.editFinishingFromQuality = new RouteLeaf('edit/:qualityId/finishing/:id', Permission.TEXSTYLE_FINISHING_EDIT);
    texStyle.machineQuality = machineQuality;

    const buggy = new BuggyRoute('buggy', Permission.VIEW_BUGGIES);
    buggy.addBuggy = new RouteLeaf('add', Permission.EDIT_BUGGIES);
    buggy.duplicateBuggy = new RouteLeaf('add/:id', Permission.EDIT_BUGGIES);
    buggy.editBuggy = new RouteLeaf('edit/:id', Permission.VIEW_BUGGIES);
    texStyle.buggy = buggy;

    const creel = new CreelRoute('creel', Permission.TEXSTYLE_CREEL_VIEW);
    creel.addCreel = new RouteLeaf('add', Permission.TEXSTYLE_CREEL_EDIT);
    creel.duplicateCreel = new RouteLeaf('add/:id', Permission.TEXSTYLE_CREEL_EDIT);
    creel.editCreel = new RouteLeaf('edit/:id', Permission.TEXSTYLE_CREEL_VIEW);
    creel.creelSettings = new RouteLeaf('settings', Permission.TEXSTYLE_CREEL_EDIT);
    texStyle.creel = creel;

    const weaveProduct = new WeaveProductRoute('weave-product', Permission.TEXSTYLE_WEAVE_PRODUCT_VIEW);
    weaveProduct.addWeaveProduct = new RouteLeaf('add', Permission.TEXSTYLE_WEAVE_PRODUCT_EDIT);
    weaveProduct.duplicateWeaveProduct = new RouteLeaf('add/:id', Permission.TEXSTYLE_WEAVE_PRODUCT_EDIT);
    weaveProduct.editWeaveProduct = new RouteLeaf('edit/:id', Permission.TEXSTYLE_WEAVE_PRODUCT_VIEW);
    texStyle.weaveProduct = weaveProduct;

    const tuftProduct = new TuftProductRoute('tuft-product', Permission.TEXSTYLE_TUFT_PRODUCT_VIEW);
    tuftProduct.addTuftProduct = new RouteLeaf('add', Permission.TEXSTYLE_TUFT_PRODUCT_EDIT);
    tuftProduct.editTuftProduct = new RouteLeaf('edit/:id', Permission.TEXSTYLE_TUFT_PRODUCT_VIEW);
    tuftProduct.duplicateTuftProduct = new RouteLeaf('add/:id', Permission.TEXSTYLE_TUFT_PRODUCT_EDIT);
    texStyle.tuftProduct = tuftProduct;

    const plasticProduct = new PlasticProductRoute('plastic-product', Permission.TEXSTYLE_PLASTIC_PRODUCT_VIEW);
    plasticProduct.addPlasticProduct = new RouteLeaf('add', Permission.TEXSTYLE_PLASTIC_PRODUCT_EDIT);
    plasticProduct.editPlasticProduct = new RouteLeaf('edit/:id', Permission.TEXSTYLE_PLASTIC_PRODUCT_VIEW);
    plasticProduct.duplicatePlasticProduct = new RouteLeaf('add/:id', Permission.TEXSTYLE_TUFT_PRODUCT_EDIT);
    texStyle.plasticProduct = plasticProduct;

    const weaveStructure = new WeaveStructureRoute('weave-structure', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.overviewWeaveStructure = new RouteLeaf('', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.addWeaveStructure = new RouteLeaf('add', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.duplicateWeaveStructure = new RouteLeaf('add/:id', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.editWeaveStructure = new RouteLeaf('edit/:id', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.weaveStructureSettings = new RouteLeaf('settings', Permission.TEXSTYLE_VIEW_WEAVE_STRUCTURES);
    weaveStructure.manageOverviewWeaveStructure = new RouteLeaf('manage', Permission.MANAGE_WEAVE_STRUCTURE_VIEW);
    weaveStructure.manageAddWeaveStructure = new RouteLeaf('manage/add', Permission.MANAGE_WEAVE_STRUCTURE_EDIT);
    texStyle.weaveStructure = weaveStructure;
    texStyle.companyWeaveStructure = new CompanyWeaveStructureRoute('company-weave-structure', Permission.COMPANY_WEAVE_STRUCTURE_VIEW);
    rootRoute.texStyle = texStyle;

    const groupedWeaveStructure = new GroupedWeaveStructureRoute('grouped-weave-structure');
    groupedWeaveStructure.overviewGroupedWeaveStructure = new RouteLeaf('', Permission.ACCESS_ALL);
    groupedWeaveStructure.addGroupedWeaveStructure = new RouteLeaf('add', Permission.ACCESS_ALL);
    groupedWeaveStructure.editGroupedWeaveStructure = new RouteLeaf('edit/:id', Permission.ACCESS_ALL);
    groupedWeaveStructure.duplicateGroupedWeaveStructure = new RouteLeaf('add/:id', Permission.ACCESS_ALL);
    texStyle.groupedWeaveStructure = groupedWeaveStructure;

    const texFab = new TexFabRoute('texfab', Permission.TEXFAB_SHOW_ICON, false);
    texFab.planning = new PlanningRoute('planning', Permission.TEXFAB_PLANNINGBOARD);
    texFab.planningPrototype = new PlanningPrototypeRoute('planning-prototype', Permission.TEXFAB_PLANNINGBOARD_NEW);

    const productionOrder = new ProductionOrderRoute('production-order', Permission.TEXFAB_SCHEDULE, true);
    productionOrder.addProductionOrder = new RouteLeaf('add');
    productionOrder.addProductionOrderNewView = new RouteLeaf('add/new');
    productionOrder.addProductionOrderForMachine = new RouteLeaf('add/machine/:id');
    productionOrder.addProductionOrderNewViewForMachine = new RouteLeaf('add/machine/new/:id');
    productionOrder.addProductionOrderForRun = new RouteLeaf('add/run');
    productionOrder.addProductionOrderNewViewForRun = new RouteLeaf('add/run/new');
    productionOrder.addProductionOrderForProductConfiguration = new RouteLeaf('add/product-configuration/:id');
    productionOrder.addProductionOrderNewViewForProductConfiguration = new RouteLeaf('add/product-configuration/:id/new');
    productionOrder.duplicateProductionOrder = new RouteLeaf('add/:id');
    productionOrder.duplicateProductionOrderNewView = new RouteLeaf('add/:id/new');
    productionOrder.editProductionOrderNewView = new RouteLeaf('edit/:id/new');
    productionOrder.editProductionOrder = new RouteLeaf('edit/:id');
    productionOrder.settings = new RouteLeaf('settings');
    productionOrder.details = new RouteLeaf('details/:id');
    texFab.productionOrder = productionOrder;

    const pathLayoutTemplate = new PathLayoutTemplateRoute('path-layout-template', Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW);
    pathLayoutTemplate.addPathLayoutTemplate = new RouteLeaf('add', Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT);
    pathLayoutTemplate.editPathLayoutTemplate = new RouteLeaf('edit/:id', Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW);
    pathLayoutTemplate.duplicatePathLayoutTemplate = new RouteLeaf('add/:id', Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT);
    pathLayoutTemplate.pathLayoutTemplateSettings = new RouteLeaf('settings', Permission.TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW);
    texFab.pathLayoutTemplate = pathLayoutTemplate;

    const weaving = new WeavingRoute('weaving', Permission.TEXFAB_WEAVE_ORDER_VIEW);
    weaving.productionOrder = new RouteLeaf('production-order', Permission.TEXFAB_WEAVE_ORDER_VIEW);
    weaving.addProductionOrder = new RouteLeaf('production-order/add', Permission.TEXFAB_WEAVE_ORDER_EDIT);
    weaving.addProductionOrderForRun = new RouteLeaf('production-order/add/run', Permission.TEXFAB_WEAVE_QUEUE);
    weaving.addProductionOrderForMachine = new RouteLeaf('production-order/add/machine/:id', Permission.TEXFAB_WEAVE_ORDER_EDIT);
    weaving.editProductionOrder = new RouteLeaf('production-order/edit/:id', Permission.TEXFAB_WEAVE_ORDER_VIEW);
    weaving.duplicateProductionOrder = new RouteLeaf('production-order/add/:id', Permission.TEXFAB_WEAVE_ORDER_EDIT);
    weaving.settings = new RouteLeaf('production-order/settings', Permission.TEXFAB_WEAVE_ORDER_VIEW);
    texFab.weaving = weaving;

    const tufting = new TuftingRoute('tufting', Permission.TEXFAB_TUFT_ORDER_VIEW);
    tufting.productionOrder = new RouteLeaf('production-order', Permission.TEXFAB_TUFT_ORDER_VIEW);
    tufting.addProductionOrder = new RouteLeaf('production-order/add', Permission.TEXFAB_TUFT_ORDER_EDIT);
    tufting.addProductionOrderForRun = new RouteLeaf('production-order/add/run', Permission.TEXFAB_TUFT_QUEUE);
    tufting.addProductionOrderForMachine = new RouteLeaf('production-order/add/machine/:id');
    tufting.editProductionOrder = new RouteLeaf('production-order/edit/:id', Permission.TEXFAB_TUFT_ORDER_VIEW);
    tufting.duplicateProductionOrder = new RouteLeaf('production-order/add/:id', Permission.TEXFAB_TUFT_ORDER_EDIT);
    tufting.settings = new RouteLeaf('production-order/settings', Permission.TEXFAB_TUFT_ORDER_VIEW);
    texFab.tufting = tufting;

    const plastic = new PlasticRoute('plastic', Permission.TEXFAB_PLASTIC_ORDER_VIEW);
    plastic.productionOrder = new RouteLeaf('production-order', Permission.TEXFAB_PLASTIC_ORDER_VIEW);
    plastic.addProductionOrder = new RouteLeaf('production-order/add', Permission.TEXFAB_PLASTIC_ORDER_EDIT);
    plastic.editProductionOrder = new RouteLeaf('production-order/edit/:id', Permission.TEXFAB_PLASTIC_ORDER_VIEW);
    plastic.duplicateProductionOrder = new RouteLeaf('production-order/add/:id', Permission.TEXFAB_PLASTIC_ORDER_EDIT);
    plastic.addProductionOrderForMachine = new RouteLeaf('production-order/add/machine/:id', Permission.TEXFAB_PLASTIC_ORDER_EDIT);
    texFab.plastic = plastic;

    const customer = new CustomerRoute('customer', Permission.TEXFAB_CUSTOMER_VIEW, true);
    customer.addCustomer = new RouteLeaf('add', Permission.TEXFAB_CUSTOMER_EDIT);
    customer.editCustomer = new RouteLeaf('edit/:id', Permission.TEXFAB_CUSTOMER_VIEW);
    texFab.customer = customer;

    const article = new ArticleRoute('article', Permission.TEXFAB_ARTICLE_VIEW, true);
    article.addArticle = new RouteLeaf('add', Permission.TEXFAB_ARTICLE_EDIT);
    article.editArticle = new RouteLeaf('edit/:id', Permission.TEXFAB_ARTICLE_VIEW);
    article.duplicateArticle = new RouteLeaf('add/:id', Permission.TEXFAB_ARTICLE_EDIT);
    texFab.article = article;

    const salesOrder = new SalesOrderRoute('sales-order', Permission.TEXFAB_SALES_ORDER_VIEW);
    salesOrder.addSalesOrder = new RouteLeaf('add', Permission.TEXFAB_SALES_ORDER_EDIT);
    salesOrder.editSalesOrder = new RouteLeaf('edit/:id', Permission.TEXFAB_SALES_ORDER_VIEW);
    salesOrder.duplicateSalesOrder = new RouteLeaf('add/:id', Permission.TEXFAB_SALES_ORDER_EDIT);
    texFab.salesOrder = salesOrder;

    const orderbook = new OrderbookRoute('orderbook', Permission.TEXFAB_ORDERBOOK);
    orderbook.settings = new RouteLeaf('settings', Permission.TEXFAB_ORDERBOOK);

    texFab.orderbook = orderbook;

    rootRoute.texFab = texFab;

    const calendar = new CalendarRoute('calendar');

    const shift = new ShiftRoute('shift', Permission.SHIFT_VIEW);
    shift.addShift = new RouteLeaf('add', Permission.SHIFT_EDIT);
    shift.editShift = new RouteLeaf('edit/:id', Permission.SHIFT_VIEW);
    shift.duplicateShift = new RouteLeaf('add/:id', Permission.SHIFT_EDIT);
    calendar.shift = shift;

    rootRoute.calendar = calendar;

    const texBot = new TexBotRoute('texbot', Permission.TEXBOT_SHOW_ICON, true);

    const machineDocumentation = new TexBotMachineDocumentationRoute('machine-documentation', Permission.TEXBOT_DOCUMENTS_VIEW, true);
    machineDocumentation.details = new RouteLeaf('details/:id', Permission.TEXBOT_DOCUMENTS_VIEW);

    const training = new TexBotTrainingRoute('training', Permission.TEXBOT_TRAINING_VIEW, true);
    training.machineDetails = new RouteLeaf('details/:machineId', Permission.TEXBOT_TRAINING_VIEW);
    training.details = new RouteLeaf('details/:machineId/:id', Permission.TEXBOT_TRAINING_VIEW);
    training.manage = new RouteLeaf('manage', Permission.TEXBOT_TRAINING_MANAGE);
    training.manageTags = new RouteLeaf('manage/tags', Permission.TEXBOT_TRAINING_MANAGE);
    training.manageDetails = new RouteLeaf('manage/:id', Permission.TEXBOT_TRAINING_MANAGE);

    texBot.machineDocumentation = machineDocumentation;
    texBot.training = training;

    rootRoute.texBot = texBot;

    const utilities = new UtilitiesRoute('utilities', Permission.TEXSTYLE_SHOW_ICON);

    const tool = new ToolRoute('tool', Permission.TEXSTYLE_PLASTIC_TOOL_VIEW);
    tool.addTool = new RouteLeaf('add', Permission.TEXSTYLE_PLASTIC_TOOL_EDIT);
    tool.editTool = new RouteLeaf('edit/:id', Permission.TEXSTYLE_PLASTIC_TOOL_VIEW);
    tool.duplicateTool = new RouteLeaf('add/:id', Permission.TEXSTYLE_PLASTIC_TOOL_EDIT);
    utilities.tool = tool;

    rootRoute.utilities = utilities;

    rootRoute.loadingFile = new RouteLeaf('loading-file');
    rootRoute.logger = new RouteLeaf('logger', Permission.ACCESS_ALL);
    rootRoute.notifications = new RouteLeaf('notifications', null, true, true);
    rootRoute.processes = new RouteLeaf('processes', Permission.ACCESS_ALL, false, true);

    const settings = new SettingsRoute('settings', Permission.SETTINGS_SHOW_ICON);
    settings.machineDataSources = new MachineDataSourceRoute('machine-data-sources', Permission.MACHINE_DATA_SOURCE_VIEW);
    settings.machineDataSources.addMachineDataSource = new RouteLeaf('add/:machineId', Permission.MACHINE_DATA_SOURCE_EDIT);
    settings.machineDataSources.editMachineDataSource = new RouteLeaf('edit/:machineId/:id', Permission.MACHINE_DATA_SOURCE_VIEW);
    settings.machineDataSources.settings = new RouteLeaf('settings', Permission.MACHINE_DATA_SOURCE_VIEW);

    const dataUnitSetupCatalog = new DataUnitSetupCatalogRoute('data-unit-setups-catalog', Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_VIEW);
    dataUnitSetupCatalog.customSetting = new RouteLeaf('settings', Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_VIEW);
    dataUnitSetupCatalog.addDataUnitSetupCatalog = new RouteLeaf('add', Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_EDIT);
    dataUnitSetupCatalog.editDataUnitSetupCatalog = new RouteLeaf('edit/:id', Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_VIEW);
    dataUnitSetupCatalog.duplicateDataUnitSetupCatalog = new RouteLeaf('add/:id', Permission.SETTINGS_DATA_UNIT_SETUP_CATALOG_EDIT);
    settings.dataUnitSetupCatalog = dataUnitSetupCatalog;

    const dataUnitSetup = new DataUnitSetupRoute('data-unit-setups', Permission.SETTINGS_DATA_UNIT_SETUP_VIEW);
    dataUnitSetup.addDataUnitSetupFromCatalog = new RouteLeaf('add-from-catalog/:id', Permission.SETTINGS_DATA_UNIT_SETUP_EDIT);
    dataUnitSetup.addDataUnitSetup = new RouteLeaf('add', Permission.SETTINGS_DATA_UNIT_SETUP_EDIT);
    dataUnitSetup.editDataUnitSetup = new RouteLeaf('edit/:id', Permission.SETTINGS_DATA_UNIT_SETUP_VIEW);
    dataUnitSetup.customSetting = new RouteLeaf('settings', Permission.SETTINGS_DATA_UNIT_SETUP_VIEW);
    settings.dataUnitSetup = dataUnitSetup;

    const resourceType = new ResourceTypeRoute('resource-types', Permission.RESOURCE_TYPE_VIEW);
    resourceType.addResourceType = new RouteLeaf('add', Permission.RESOURCE_TYPE_EDIT);
    resourceType.editResourceType = new RouteLeaf('edit/:id', Permission.RESOURCE_TYPE_VIEW);
    resourceType.duplicateResourceType = new RouteLeaf('add/:id', Permission.RESOURCE_TYPE_EDIT);
    settings.resourceType = resourceType;

    const physicalQuantity = new PhysicalQuantityRoute('physical-quantities', Permission.PHYSICAL_QUANTITY_VIEW);
    physicalQuantity.addPhysicalQuantity = new RouteLeaf('add', Permission.PHYSICAL_QUANTITY_EDIT);
    physicalQuantity.editPhysicalQuantity = new RouteLeaf('edit/:id', Permission.PHYSICAL_QUANTITY_VIEW);
    physicalQuantity.duplicatePhysicalQuantity = new RouteLeaf('add/:id', Permission.PHYSICAL_QUANTITY_EDIT);
    settings.physicalQuantity = physicalQuantity;

    const declaration = new DeclarationRoute('declaration', Permission.SETTINGS_DECLARATIONS_VIEW);
    declaration.addDeclaration = new RouteLeaf('add', Permission.SETTINGS_DECLARATIONS_EDIT);
    declaration.editDeclaration = new RouteLeaf('edit/:id', Permission.SETTINGS_DECLARATIONS_VIEW);
    settings.declaration = declaration;

    const deviceTemplate = new DeviceTemplateRoute('device-template', Permission.DEVICE_TEMPLATE_VIEW);
    deviceTemplate.addDeviceTemplate = new RouteLeaf('add', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.editDeviceTemplate = new RouteLeaf('edit/:id', Permission.DEVICE_TEMPLATE_VIEW);
    deviceTemplate.duplicateDeviceTemplate = new RouteLeaf('add/:id', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.addCounter = new RouteLeaf('counter/add', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.editCounter = new RouteLeaf('counter/edit/:id', Permission.DEVICE_TEMPLATE_VIEW);
    deviceTemplate.duplicateCounter = new RouteLeaf('counter/add/:id', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.addConfiguration = new RouteLeaf('configuration/add', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.editConfiguration = new RouteLeaf('configuration/edit/:id', Permission.DEVICE_TEMPLATE_VIEW);
    deviceTemplate.duplicateConfiguration = new RouteLeaf('configuration/add/:id', Permission.DEVICE_TEMPLATE_EDIT);
    deviceTemplate.addDeviceTemplateFromCatalog = new RouteLeaf('add-from-catalog/:id', Permission.DEVICE_TEMPLATE_EDIT);
    settings.deviceTemplate = deviceTemplate;

    const deviceTemplateCatalog = new DeviceTemplateCatalogRoute('device-template-catalog', Permission.ADMIN_DEVICE_TEMPLATE_VIEW);
    deviceTemplateCatalog.addDeviceTemplateCatalog = new RouteLeaf('add', Permission.ADMIN_DEVICE_TEMPLATE_EDIT);
    deviceTemplateCatalog.editDeviceTemplateCatalog = new RouteLeaf('edit/:id', Permission.ADMIN_DEVICE_TEMPLATE_VIEW);
    deviceTemplateCatalog.duplicateDeviceTemplateCatalog = new RouteLeaf('add/:id', Permission.ADMIN_DEVICE_TEMPLATE_EDIT);
    deviceTemplateCatalog.addCounterCatalog = new RouteLeaf('counter/add', Permission.ADMIN_DEVICE_TEMPLATE_EDIT);
    deviceTemplateCatalog.editCounterCatalog = new RouteLeaf('counter/edit/:id', Permission.ADMIN_DEVICE_TEMPLATE_VIEW);
    deviceTemplateCatalog.duplicateCounterCatalog = new RouteLeaf('counter/add/:id', Permission.ADMIN_DEVICE_TEMPLATE_EDIT);
    deviceTemplateCatalog.addConfigurationCatalog = new RouteLeaf('configuration/add', Permission.ADMIN_DEVICE_TEMPLATE_EDIT);
    deviceTemplateCatalog.editConfigurationCatalog = new RouteLeaf('configuration/edit/:id', Permission.ADMIN_DEVICE_TEMPLATE_VIEW);
    deviceTemplateCatalog.duplicateConfigurationCatalog = new RouteLeaf('configuration/add/:id', Permission.ADMIN_DEVICE_TEMPLATE_VIEW);
    settings.deviceTemplateCatalog = deviceTemplateCatalog;

    const reportItem = new ReportItemRoute('report-items', Permission.SETTINGS_REPORT_ITEM_VIEW);
    reportItem.editReportItem = new RouteLeaf('edit/:id', Permission.SETTINGS_REPORT_ITEM_VIEW);
    settings.reportItem = reportItem;

    const event = new EventRoute('events', Permission.ALERT_VIEW_EVENTS);
    event.addEvent = new RouteLeaf('add', Permission.ALERT_VIEW_EVENTS);
    event.duplicateEvent = new RouteLeaf('add/:id', Permission.ALERT_VIEW_EVENTS);
    event.editEvent = new RouteLeaf('edit/:id', Permission.ALERT_VIEW_EVENTS);
    settings.event = event;

    const alert = new AlertRoute('alerts', Permission.ALERT_VIEW_EVENTS);
    alert.addAlert = new RouteLeaf('add', Permission.ALERT_VIEW_EVENTS);
    alert.editAlert = new RouteLeaf('edit/:id', Permission.ALERT_VIEW_EVENTS);
    settings.alert = alert;

    settings.automaticStopGroup = new RouteLeaf('automatic-stop-groups', Permission.SETTINGS_AUTOMATIC_STOP_GROUP_VIEW);

    const operator = new OperatorRoute('operators', Permission.SETTINGS_OPERATOR_VIEW);
    operator.addOperator = new RouteLeaf('add', Permission.SETTINGS_OPERATOR_EDIT);
    operator.editOperator = new RouteLeaf('edit/:id', Permission.SETTINGS_OPERATOR_VIEW);
    operator.duplicateOperator = new RouteLeaf('add/:id', Permission.SETTINGS_OPERATOR_EDIT);
    operator.addOperatorType = new RouteLeaf('operator-type/add', Permission.SETTINGS_OPERATOR_TYPE_EDIT);
    operator.editOperatorType = new RouteLeaf('operator-type/edit/:id', Permission.SETTINGS_OPERATOR_TYPE_VIEW);
    settings.operator = operator;

    const rejectReason = new RejectReasonRoute('reject-reasons', Permission.REJECT_REASON_VIEW);
    rejectReason.addRejectReason = new RouteLeaf('add', Permission.REJECT_REASON_EDIT);
    rejectReason.editRejectReason = new RouteLeaf('edit/:id', Permission.REJECT_REASON_VIEW);
    rejectReason.duplicateRejectReason = new RouteLeaf('add/:id', Permission.REJECT_REASON_EDIT);
    settings.rejectReason = rejectReason;

    rootRoute.settings = settings;

    return rootRoute;
  }
}
