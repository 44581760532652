export enum Permission {
  TEXBOT_SHOW_ICON,
  TEXBOT_DOCUMENTS_VIEW,
  TEXBOT_TRAINING_VIEW,
  TEXBOT_TRAINING_MANAGE,

  TEXEDIT_SHOW_ICON,
  TEXEDIT_CADCAM_PRODUCT_CONFIGURATION_VIEW,
  TEXEDIT_CADCAM_WEAVESTRUCTURE_EDIT,
  TEXEDIT_VIEW_EP,
  TEXEDIT_EDIT,
  TEXEDIT_PROC,
  TEXEDIT_ADVANCED_PROC,
  TEXEDIT_TUFTLINK,
  TEXEDIT_EDIT_DRAWING_LIBRARY,
  TEXEDIT_VIEW_DRAWING_LIBRARY,
  TEXEDIT_DESIGN_YARN_PERCENTAGES,
  TEXEDIT_DRAWING_COLOR_MAP,
  TEXEDIT_PREVIEW_BMP,

  TEXSTYLE_SHOW_ICON,
  TEXSTYLE_VIEW,
  TEXSTYLE_EDIT,
  TEXSTYLE_VIEW_WEAVE_STRUCTURES,
  TEXSTYLE_EDIT_WEAVE_STRUCTURES,
  TEXSTYLE_WEAVE_STRUCTURES_EDIT_PATTERN,
  TEXSTYLE_QUALITY_SELVEDGES_VIEW,
  TEXSTYLE_QUALITY_SELVEDGES_EDIT_PATTERN,
  TEXSTYLE_QUALITY_SELVEDGES_EDIT_LABEL,
  TEXSTYLE_QUALITY_EDIT_ALTERNATIVES,
  TEXSTYLE_MACHINE_QUALITY_PATH_WIDTHS,
  TEXSTYLE_COLOR_VIEW,
  TEXSTYLE_COLOR_EDIT,
  TEXSTYLE_YARN_VIEW,
  TEXSTYLE_YARN_EDIT,
  TEXSTYLE_COLORSET_VIEW,
  TEXSTYLE_COLORSET_EDIT,
  TEXSTYLE_YARNSET_VIEW,
  TEXSTYLE_YARNSET_EDIT,
  TEXSTYLE_COLOREDYARNSET_VIEW,
  TEXSTYLE_COLOREDYARNSET_EDIT,
  TEXSTYLE_WEFTCOLOREDYARNSET_VIEW,
  TEXSTYLE_WEFTCOLOREDYARNSET_EDIT,
  TEXSTYLE_CREEL_VIEW,
  TEXSTYLE_CREEL_EDIT,
  TEXSTYLE_TUFT_PRODUCT_VIEW,
  TEXSTYLE_TUFT_PRODUCT_EDIT,
  TEXSTYLE_WEAVE_PRODUCT_VIEW,
  TEXSTYLE_WEAVE_PRODUCT_EDIT,
  TEXSTYLE_PLASTIC_PRODUCT_VIEW,
  TEXSTYLE_PLASTIC_PRODUCT_EDIT,
  TEXSTYLE_FINISHING_VIEW,
  TEXSTYLE_FINISHING_EDIT,
  TEXSTYLE_MACHINE_QUALITY_VIEW,
  TEXSTYLE_MACHINE_QUALITY_EDIT,
  TEXSTYLE_PLASTIC_TOOL_VIEW,
  TEXSTYLE_PLASTIC_TOOL_EDIT,
  TEXSTYLE_STOCK_LOCATION_VIEW,
  TEXSTYLE_STOCK_LOCATION_EDIT,

  TEXFAB_SHOW_ICON,
  TEXFAB_VIEWEP,
  TEXFAB_SCHEDULE,
  TEXFAB_PLANNINGBOARD,
  TEXFAB_PLANNINGBOARD_NEW,
  TEXFAB_CARPET_PLANNING,
  TEXFAB_WEAVE_PLANNING,
  TEXFAB_TUFT_PLANNING,
  TEXFAB_PLASTIC_PLANNING,
  TEXFAB_PROJECTS,
  TEXFAB_YARNCONSUMPTION,
  TEXFAB_YARNCONSUMPTION_FOR_SMARTCREEL_PVD_FILE,
  TEXFAB_SMARTCREEL,
  TEXFAB_VIEW,
  TEXFAB_ORDERBOOK,
  TEXFAB_CARPET_QUEUE,
  TEXFAB_TUFT_QUEUE,
  TEXFAB_TUFT_ORDER_VIEW,
  TEXFAB_TUFT_ORDER_EDIT,
  TEXFAB_WEAVE_QUEUE,
  TEXFAB_WEAVE_ORDER_VIEW,
  TEXFAB_WEAVE_ORDER_EDIT,
  TEXFAB_PLASTIC_QUEUE,

  TEXFAB_CUSTOMER_VIEW,
  TEXFAB_CUSTOMER_EDIT,
  TEXFAB_ARTICLE_VIEW,
  TEXFAB_ARTICLE_EDIT,
  TEXFAB_SALES_ORDER_VIEW,
  TEXFAB_SALES_ORDER_EDIT,
  TEXFAB_PATH_LAYOUT_TEMPLATE_VIEW,
  TEXFAB_PATH_LAYOUT_TEMPLATE_EDIT,
  TEXFAB_NEW_BUILDER,
  TEXFAB_QUANTITY_SUGGESTION,
  TEXFAB_SCHEDULE_FEEDBACK,
  TEXFAB_VERIFY_NEW,
  TEXFAB_PLASTIC_ORDER_VIEW,
  TEXFAB_PLASTIC_ORDER_EDIT,

  TEXBI_SHOW_ICON,
  TEXBI_EVENT_BOARD_VIEW,
  TEXBI_EVENT_BOARD_EDIT,
  TEXBI_READ_A_REPORT,
  TEXBI_EXTRUSION_VIBRATION_FORM,
  TEXBI_EXTRUSION_VIBRATION_REPORT,

  TEXSTATE_VIEW,
  TEXSTATE_FILETRANSFER,
  TEXSTATE_EDIT_MACHINE,
  TEXSTATE_VIEW_LIVE,
  TEXSTATE_SHOW_ICON,
  TEXSTATE_VNC,
  TEXSTATE_MANAGE_GROUPS,
  TEXSTATE_MANAGE_FLOORPLAN_ITEM,
  TEXSTATE_VIEW_LIVE_STATUS,
  TEXSTATE_VIEW_LIVE_STARTSTOP_MESSAGES,
  TEXSTATE_VIEW_LIVE_DECLARATION_MESSAGES,
  TEXSTATE_VIEW_LIVE_ORDER_MESSAGES,
  TEXSTATE_VIEW_LIVE_FIXED_DATA_TEMPLATE,
  TEXSTATE_VIEW_HISTORY_MESSAGES,
  TEXSTATE_VIEW_DYNAMIC_DATA_TEMPLATE,
  TEXSTATE_EDIT_DYNAMIC_DATA_TEMPLATE,

  SETTINGS_SHOW_ICON,
  SETTINGS_DECLARATIONS_VIEW,
  SETTINGS_DECLARATIONS_EDIT,
  SETTINGS_DATA_UNIT_SETUP_VIEW,
  SETTINGS_DATA_UNIT_SETUP_EDIT,
  SETTINGS_DATA_UNIT_SETUP_CATALOG_VIEW,
  SETTINGS_DATA_UNIT_SETUP_CATALOG_EDIT,
  SETTINGS_DATA_UNIT_SETUP_CATALOG_REVIEW,
  SETTINGS_OPERATOR_VIEW,
  SETTINGS_OPERATOR_EDIT,
  SETTINGS_OPERATOR_TYPE_VIEW,
  SETTINGS_OPERATOR_TYPE_EDIT,
  SETTINGS_REPORT_ITEM_VIEW,
  SETTINGS_REPORT_ITEM_EDIT,
  SETTINGS_AUTOMATIC_STOP_GROUP_VIEW,
  SETTINGS_AUTOMATIC_STOP_GROUP_EDIT,
  REJECT_REASON_VIEW,
  REJECT_REASON_EDIT,

  SALES_VIEW,
  DESIGNS_VIEW,

  ALERT_VIEW_NOTIFICATIONS,
  ALERT_VIEW_EVENTS,
  ALERT_EDIT_EVENTS,

  ACCESS_ALL,

  MANAGEMENT_APPLICATION,
  PROFILE_CREDITS,
  PROFILE_SPEEDTEST,
  JOB_VIEW,
  CHANGE_INDIVIDUAL_FINISHING,
  ADD_PATH_LABEL,
  SCHEDULE_DUE_DATE,
  PRINT_WEAVE_ORDER,
  PRINT_BUGGY_OVERVIEW,
  VIEW_LOSS,
  VIEW_MIN_MAX_BUGGY,
  CAN_SIGN_TERMSANDCONDITIONS,
  FINISHING_LABEL,
  FINISHING_CUTTINGLINE,
  ADD_JUTE,

  PLAN_IN_BUGGIES,
  VIEW_BUGGIES,
  EDIT_BUGGIES,
  FIXED_SCHEDULE_VIEW,
  FIXED_SCHEDULE_EDIT,
  SHIFT_VIEW,
  SHIFT_EDIT,
  MANAGE_WEAVE_STRUCTURE_VIEW,
  MANAGE_WEAVE_STRUCTURE_EDIT,
  COMPANY_WEAVE_STRUCTURE_VIEW,
  COMPANY_WEAVE_STRUCTURE_EDIT,

  PHYSICAL_QUANTITY_VIEW,
  PHYSICAL_QUANTITY_EDIT,

  RESOURCE_TYPE_VIEW,
  RESOURCE_TYPE_EDIT,

  MACHINE_DATA_SOURCE_VIEW,
  MACHINE_DATA_SOURCE_EDIT,

  ADMIN_DEVICE_TEMPLATE_VIEW,
  ADMIN_DEVICE_TEMPLATE_EDIT,
  DEVICE_TEMPLATE_VIEW,
  DEVICE_TEMPLATE_EDIT,

  ADD_MANUAL_JUTE
}
