<ng-container *ngIf="!inProgress; else loading">
  <div *ngIf="!canShowBMSTheme" #inputField class="input-field full-height" [class.has-items]="!isEmpty()" [class.cursor-pointer]="canClickInput()" (click)="onInputClicked()">
    <div class="objects">
      <div *ngIf="!isEmpty(); else noItemsSelected">
        <div class="chips">
          <vdw-input-chip
            #visibleInputChips
            *ngFor="let item of inputChips"
            class="full-max-width"
            [class.hidden]="item.hidden"
            [value]="item.value"
            [route]="item.url"
            [multiSelect]="multiSelect"
            [color]="item.colorInHex"
            [error]="ngControl?.errors && ngControl.touched"
            [disabled]="disabled"
            (removeClick)="removeChip(item.id)"
          ></vdw-input-chip>
          <div
            *ngIf="overflowAmount > 0"
            class="quantity-dropdown cursor-pointer"
            [class.invalid]="ngControl?.errors && ngControl.touched"
            [class.disabled]="disabled"
            #overflowChipTrigger="matMenuTrigger"
            [matMenuTriggerFor]="overflowChips"
          >
            <p class="b1">+{{ overflowAmount }}</p>
          </div>
        </div>
      </div>
      <ng-template #noItemsSelected>
        <p class="placeholder">
          <vdw-ellipsis-label [text]="getPlaceholderText()" matTooltipPosition="above"></vdw-ellipsis-label>
        </p>
      </ng-template>
    </div>
    <button *ngIf="!isEmpty() && !disabled" type="button" mat-icon-button mat-dialog-close class="mat-icon-action-button-sm-without-border" (click)="deleteSelection($event)">
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <button *ngIf="!disabled" type="button" mat-stroked-button class="select-button" (click)="onSelectClicked($event)">{{ getButtonValue() | bmsTranslate }}</button>
  </div>
  <div
    *ngIf="canShowBMSTheme"
    #inputField
    class="bms-theme input-field full-height"
    [class.has-items]="!isEmpty()"
    #disabledDropdownTrigger="matMenuTrigger"
    [matMenuTriggerFor]="disabledDropdownMenu"
  >
    <mat-select
      *ngIf="!withDialogObjectSelection"
      #dropdownSelect
      disableOptionCentering
      [placeholder]="getPlaceholderText()"
      class="bms-theme overflow-hidden"
      [multiple]="multiSelect"
      [formControl]="ngControl?.control"
      [(value)]="value"
      [panelClass]="ngControl?.control?.invalid ? 'bms-theme select-panel-invalid' : 'bms-theme'"
    >
      <mat-select-trigger>
        <div class="chips">
          <vdw-input-chip
            #visibleInputChips
            *ngFor="let item of inputChips"
            class="full-max-width"
            [class.hidden]="item.hidden"
            [value]="item.value"
            [route]="item.url"
            [multiSelect]="multiSelect && !disabled"
            [color]="item.colorInHex"
            [error]="ngControl?.errors && ngControl.touched"
            [canShowBMSTheme]="canShowBMSTheme"
            (removeClick)="removeChip(item.id)"
          ></vdw-input-chip>
          <div
            *ngIf="overflowAmount > 0"
            class="quantity-dropdown"
            [class.invalid]="ngControl?.errors && ngControl.touched"
            [class.disabled]="disabled"
            [class.menu-openned]="disabledDropdownTrigger.menuOpen || formControl?.panelOpen"
          >
            <p class="b2">+{{ overflowAmount }}</p>
          </div>
        </div>
      </mat-select-trigger>
      <mat-form-field class="full-width search-input">
        <input
          matInput
          [placeholder]="'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.TYPE_TO_SEARCH' | bmsTranslate"
          class="full-width mb-8"
          [(ngModel)]="filteredText"
          (click)="$event.stopPropagation()"
        />
        <button *ngIf="filteredText" class="clear-input-button" tabindex="-1" type="button" mat-icon-button (click)="filteredText = ''">
          <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
        </button>
      </mat-form-field>
      <ng-container *ngTemplateOutlet="noResults"></ng-container>
      <div class="options-content overflow-auto" [class.hidden]="canShowNoResult()">
        <mat-checkbox *ngIf="multiSelect" [indeterminate]="canShowIndeterminate()" [checked]="canShowChecked()" (change)="selectAll($event)">{{
          'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.SELECT_ALL' | bmsTranslate
        }}</mat-checkbox>
        <mat-option *ngFor="let option of listOfOptions" [value]="option" [class.hidden]="!isFiltered(option)">
          <div class="flex-row flex-space-between gap-8">
            <span *ngIf="isColor(option)" class="color-preview" [style.background-color]="option.hexadecimalColorCode"></span>
            <vdw-ellipsis-label [text]="getTranslation(option)" matTooltipPosition="above"></vdw-ellipsis-label>
            <button
              mat-icon-button
              type="button"
              (click)="openDetails($event, option)"
              [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.OPEN_DETAILS' | bmsTranslate"
              matTooltipPosition="above"
            >
              <mat-icon svgIcon="solid-open-in-full" class="icon-16"></mat-icon>
            </button>
          </div>
        </mat-option>
      </div>
    </mat-select>

    <div *ngIf="withDialogObjectSelection" class="chips full-width overflow-hidden">
      <div class="select-overlay cursor-pointer" (click)="onInputClickedWithDialogObjectSelection()"></div>
      <vdw-ellipsis-label *ngIf="isEmpty()" class="disabled" [text]="getPlaceholderText()" />
      <vdw-input-chip
        #visibleInputChips
        *ngFor="let item of inputChips"
        class="full-max-width"
        [value]="item.value"
        [route]="item.url"
        [multiSelect]="multiSelect && !disabled"
        [color]="item.colorInHex"
        [error]="ngControl?.errors && ngControl.touched"
        [canShowBMSTheme]="canShowBMSTheme"
        (removeClick)="removeChip(item.id)"
      ></vdw-input-chip>
      <div
        *ngIf="overflowAmount > 0"
        class="quantity-dropdown"
        [class.invalid]="ngControl?.errors && ngControl.touched"
        [class.disabled]="disabled"
        [class.menu-openned]="disabledDropdownTrigger.menuOpen || formControl?.panelOpen"
      >
        <p class="b2">+{{ overflowAmount }}</p>
      </div>
    </div>
    <button *ngIf="!isEmpty() && !disabled" class="clear-input-button" tabindex="-1" type="button" mat-icon-button (click)="deleteAllSelection($event)">
      <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
    </button>
    <mat-icon class="icon-16" [svgIcon]="isObject ? 'solid-unfold-more' : 'solid-keyboard-arrow-down'"></mat-icon>
  </div>
</ng-container>
<ng-template #loading>
  <div class="spinner flex-row full-width full-height" [class.invalid]="ngControl?.errors && ngControl.touched" [class.bms-theme]="canShowBMSTheme">
    <mat-spinner *ngIf="canShowBMSTheme" diameter="16" mode="determinate" value="100"></mat-spinner>
    <mat-spinner [diameter]="canShowBMSTheme ? 16 : 18" mode="indeterminate"></mat-spinner>
  </div>
</ng-template>

<mat-menu class="mat-elevation-z0 overflow-chips" #overflowChips="matMenu">
  <vdw-input-chip
    *ngFor="let item of getOverflowChips()"
    [value]="item.value"
    [route]="item.url"
    [multiSelect]="multiSelect"
    [color]="item.colorInHex"
    [error]="ngControl?.errors && ngControl.touched"
    [disabled]="disabled"
    (removeClick)="removeChip(item.id)"
  >
  </vdw-input-chip>
</mat-menu>

<mat-menu *ngIf="disabled && listOfOptions?.length > 0" class="mat-elevation-z0 bms-theme disabled-dropdown" #disabledDropdown="matMenu" (click)="$event.stopPropagation()">
  <mat-form-field class="full-width search-input">
    <input matInput [placeholder]="'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.TYPE_TO_SEARCH' | bmsTranslate" class="full-width mb-8" [(ngModel)]="filteredText" (click)="$event.stopPropagation()" />
    <button *ngIf="filteredText" class="clear-input-button" tabindex="-1" mat-icon-button (click)="resetFilterText($event)" type="button">
      <mat-icon svgIcon="solid-close" class="icon-12"></mat-icon>
    </button>
  </mat-form-field>
  <ng-container *ngTemplateOutlet="noResults"></ng-container>
  <div class="options-content overflow-auto" [class.hidden]="canShowNoResult()">
    <mat-option *ngFor="let option of listOfOptions" [value]="option" (click)="$event.stopPropagation()" [class.hidden]="!isFiltered(option)">
      <div class="flex-row flex-space-between gap-8">
        <span *ngIf="isColor(option)" class="color-preview" [style.background-color]="option.hexadecimalColorCode"></span>
        <vdw-ellipsis-label [text]="getTranslation(option)" matTooltipPosition="above"></vdw-ellipsis-label>
        <button mat-icon-button (click)="openDetails($event, option)" [matTooltip]="'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.OPEN_DETAILS' | bmsTranslate" matTooltipPosition="above" type="button">
          <mat-icon svgIcon="solid-open-in-full" class="icon-16"></mat-icon>
        </button>
      </div>
    </mat-option>
  </div>
</mat-menu>

<ng-template #noResults>
  <p *ngIf="canShowNoResult()" class="no-results-found">{{ 'ANGULAR_COMPONENT_LIBRARY.OBJECT_SELECTION.NO_RESULTS_FOUND' | bmsTranslate }}</p>
</ng-template>
