import {Injectable} from '@angular/core';
import {filter, Observable, of} from 'rxjs';
import {AssertionUtils} from '../../../common/utils/assertion-utils';
import {DialogBuilderFactoryService} from '../../../dialogs/dialog-builder/dialog-builder-factory.service';
import {GridOptionsBuilderFactoryService} from '../../../grids/grid-options-builder-factory.service';
import {ContentSwitcherDialogPrototypeComponent} from '../../content-switcher-prototype/content-switcher-dialog-prototype.component';
import {ContentSwitcherDialogPrototypeService} from '../../content-switcher-prototype/content-switcher-dialog-prototype.service';
import {ContentSwitcherDialogComponent} from '../../content-switcher/content-switcher-dialog.component';
import {ContentSwitcherDialogService} from '../../content-switcher/content-switcher-dialog.service';
import {CrudObjectSelectionDialogComponent} from '../crud-object-selection-dialog/crud-object-selection-dialog.component';
import {CrudOverviewDataBuilder} from '../crud-overview-data-builder';
import {CrudOverviewDataConfig} from '../interfaces/crud-overview-data-config.interface';

@Injectable({
  providedIn: 'root'
})
export class CrudOverviewDataBuilderFactoryService<T = unknown> {
  public constructor(
    private readonly contentSwitcher: ContentSwitcherDialogService,
    private readonly contentSwitcherPrototype: ContentSwitcherDialogPrototypeService,
    private readonly dialogBuilderFactory: DialogBuilderFactoryService,
    private readonly gridOptionsBuilderFactoryService: GridOptionsBuilderFactoryService
  ) {}

  public getBuilder<TBuilder extends T = T>(): CrudOverviewDataBuilder<TBuilder> {
    return new CrudOverviewDataBuilder<TBuilder>(this.gridOptionsBuilderFactoryService, this.contentSwitcher);
  }

  public openObjectSelectionDialog<TDialog extends T = T>(config: CrudOverviewDataConfig<TDialog>): Observable<TDialog[]> {
    return this.dialogBuilderFactory
      .getBuilder()
      .withWidth('80vw')
      .withMinWidth(900)
      .withHeight('80vh')
      .withMinHeight(480)
      .openCrudDialog(CrudObjectSelectionDialogComponent, {config})
      .pipe(filter((data: unknown) => !AssertionUtils.isNullOrUndefined(data))) as Observable<TDialog[]>;
  }

  public openContentSwitcherDialog<TDialog extends T = T>(): Observable<TDialog[]> {
    return !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog)
      ? of()
      : (this.dialogBuilderFactory
          .getBuilder()
          .withWidth('80vw')
          .withMinWidth(900)
          .withHeight('80vh')
          .withCloseDisabled()
          .withMinHeight(480)
          .openDialog(ContentSwitcherDialogComponent, {})
          .pipe(filter((data: unknown) => !AssertionUtils.isNullOrUndefined(data))) as Observable<TDialog[]>);
  }

  public openContentSwitcherDialogPrototype<TDialog extends T = T>(title: string = null): Observable<TDialog[]> {
    this.contentSwitcherPrototype.setHeaderTitle(title);

    return !AssertionUtils.isNullOrUndefined(this.contentSwitcher.dialog)
      ? of()
      : (this.dialogBuilderFactory
          .getBuilder()
          .withWidth('80vw')
          .withMinWidth(900)
          .withHeight('80vh')
          .withCloseDisabled()
          .withMinHeight(480)
          .openDialog(ContentSwitcherDialogPrototypeComponent, {})
          .pipe(filter((data: unknown) => !AssertionUtils.isNullOrUndefined(data))) as Observable<TDialog[]>);
  }
}
