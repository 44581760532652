import {ComponentRef, Injectable, Injector, Type} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AssertionUtils} from '../../common/utils/assertion-utils';
import {ComponentInfo} from './component-info.interface';
import {ContentSwitcherEntry} from './content-switcher-entry.interface';

@Injectable({providedIn: 'root'})
export class ContentSwitcherDialogPrototypeService<T = unknown> {
  public headerTitle: string;
  public parentInjector: Injector;
  public dialog: MatDialogRef<any>;
  public activeEntry: ContentSwitcherEntry;
  public navigationHistory: ContentSwitcherEntry[] = [];

  private readonly dialogClosed: Subject<void> = new Subject<void>();
  private readonly cachedPreviousEntry: ComponentInfo;

  public navigateForwardComponent: BehaviorSubject<Type<any> | null> = new BehaviorSubject<Type<any> | null>(null);
  public navigateBackComponent: Subject<ComponentRef<any>> = new Subject();

  public setHeaderTitle(title: string): void {
    this.headerTitle = title;
  }

  public reset(): void {
    this.dialog = null;
    this.activeEntry = null;
    this.navigationHistory = [];
  }

  public getActiveComponentData(): T {
    return this.activeEntry ? this.activeEntry.componentInfo.data : null;
  }

  public getCachedPreviousEntry(): ComponentInfo {
    return this.cachedPreviousEntry;
  }

  public hasPreviousContainerRef(): boolean {
    return !AssertionUtils.isNullOrUndefined(this.navigationHistory[this.navigationHistory.length - 1]?.componentRef)
  }

  public getEntry(key: string): ComponentInfo {
    if (AssertionUtils.isEmpty(this.navigationHistory)) {
      return null;
    }

    const foundEntry = this.navigationHistory.find((history: ContentSwitcherEntry) => history.componentInfo.key === key);

    if (!AssertionUtils.isNullOrUndefined(foundEntry)) {
      return foundEntry.componentInfo;
    }

    return this.cachedPreviousEntry?.key === key ? this.cachedPreviousEntry : null;
  }

  public navigateBack(data?: any): void {
    const previous = this.navigationHistory.pop();

    if (!AssertionUtils.isNullOrUndefined(previous)) {
      this.activeEntry.navigateBackData.next(data);
      this.activeEntry = previous;
      this.navigateBackComponent.next(this.activeEntry.componentRef);
    } else {
      this.dialogClosed.next();
      this.dialog.close(data);
    }
  }

  public navigateForward<Q>(componentInfo: ComponentInfo): Observable<Q> {
    if (!AssertionUtils.isNullOrUndefined(this.activeEntry)) {
      this.navigationHistory.push(this.activeEntry);
    }

    this.activeEntry = {componentInfo, componentRef: null, navigateBackData: new Subject<Q>()} as ContentSwitcherEntry;
    this.navigateForwardComponent.next(this.activeEntry.componentInfo.component);

    return this.activeEntry.navigateBackData.asObservable();
  }
}
